import ChatRoot from "../chat/ChatRoot";
import * as React from "react";
import {useState} from "react";
import {useParams} from "react-router-dom";
import TagImagesDialog from "../components/answer-dialog/TagImagesDialog";
import TagOptionsDialog from "../components/answer-dialog/TagOptionsDialog";
import ResultsDialog from "../components/results-dialog/ResultsDialog";
import {useTranslation} from "react-i18next";
import CountdownOverlay from "./CountdownOverlay";
import {usePuzzleContext} from "../context/PuzzleContextProvider";
import gameApiV2 from "../api/gameApiV2";
import {usePuzzleWaitingAnswersContext} from "../context/PuzzleWaitingAnswersContextProvider";
import {useRecoilState} from "recoil";
import {globalErrorState} from "../recoil_state";
import useIsGuest from "../hooks/useIsGuest";
import FillGapsDialog from "../components/answer-dialog/gap-fill/FillGapsDialog";

export const DIFFICULTIES_OPTIONS = { //mapped to java's object!
    STANDARD: "STANDARD",
    CHALLENGING: "CHALLENGING"
}

export default function Game({ matchReady }) {
    const { playerNames, gameData, myMemberId, setGameData, puzzleStateResult } = usePuzzleContext();
    const { hasAllAnswers } = usePuzzleWaitingAnswersContext();
    const {roomId, puzzleId} = useParams();
    const [tagImages, setTagImages] = useState(null);
    const { isGuest } = useIsGuest(roomId);

    const [tagOptions, setTagOptions] = useState(null);
    const [showTagOptions, setShowTagOptions] = useState(false);

    const [orderTagOptions, setOrderTagOptions] = useState(null);
    const [showOrderTagOptions, setShowOrderTagOptions] = useState(false);

    const [dragFillUniqueOptions, setDragFillUniqueOptions] = useState(null);
    const [showDragFillUniqueDialog, setShowDragFillUniqueDialog] = useState(false);

    const [dragFillOptions, setDragFillOptions] = useState(null);
    const [showDragFillDialog, setShowDragFillDialog] = useState(false);


    const [showResultsDialog, setShowResultsDialog] = useState(false);

    const { t: tPuzzle } = useTranslation('puzzle');

    const gameName = tPuzzle(gameData?.gameName);
    const puzzleName = tPuzzle(gameData?.puzzle?.name);
    const [, setError] = useRecoilState(globalErrorState);

    const [pendingMessage, setPendingMessage] = useState(null);
    const [sendError, setSendError] = useState(null);

    const handleSendMessageError = (err) => {
        setSendError(err);
        if (err?.response?.data) {
            setError(err?.response?.data)
        }
    }

    const attempt = puzzleStateResult?.data?.attempts;

    const sendAnswer = (innerHtml, textContent, innerText, nodes) => {
        const req = {message: textContent, attempt}
        setPendingMessage({ title: `${textContent}`, sender: isGuest ? 'guest' : 'player', time: new Date() });
        setSendError(null);
        gameApiV2.sendMessage(roomId, puzzleId, req, myMemberId)
            .then(response => setGameData(response?.data))
            .catch(handleSendMessageError)
            .finally(() => setPendingMessage(null))
    }

    const onTagImages = (message) => {
        setTagImages(message);
    }

    const onTagOptions = (message) => {
        setShowTagOptions(true);
        setTagOptions({ message, values: message?.action?.options ?? [] });
    }

    const onOrderTagOptions = (message) => {
        setShowOrderTagOptions(true);
        setOrderTagOptions({ message, values: message?.action?.options ?? [] });
    }

    const onDragFillUnique = (message) => {
        setShowDragFillUniqueDialog(true);
        setDragFillUniqueOptions({ message, values: message?.action?.options ?? [] });
    }

    const onDragFill = (message) => {
        setShowDragFillDialog(true);
        setDragFillOptions({ message, values: message?.action?.options ?? [] });
    }

    const onTagOptionsSubmit = (answersArray) => {
        // we will order alphabetically before sending
        answersArray.sort((a, b) => a.localeCompare(b))
        const finalAnswer = answersArray.join('-');
        const req = {message: finalAnswer, data: gameData, attempt}
        gameApiV2.sendMessage(roomId, puzzleId, req, myMemberId)
            .then(response => setGameData(response?.data))
            .catch(handleSendMessageError);
    }

    const onOrderTagOptionsSubmit = (answersArray) => {
        const finalAnswer = answersArray.join('-');
        const req = {message: finalAnswer, data: gameData, attempt}
        gameApiV2.sendMessage(roomId, puzzleId, req, myMemberId)
            .then(response => setGameData(response?.data))
            .catch(handleSendMessageError);
    }

    const onDragFillUniqueSubmit = (answersArray) => {
        const finalAnswer = answersArray.join('-');
        const req = {message: finalAnswer, data: gameData, attempt}
        gameApiV2.sendMessage(roomId, puzzleId, req, myMemberId)
            .then(response => setGameData(response?.data))
            .catch(handleSendMessageError);
    }

    const onDragFillSubmit = (answersArray) => {
        const finalAnswer = answersArray.join('-');
        const req = {message: finalAnswer, data: gameData, attempt}
        gameApiV2.sendMessage(roomId, puzzleId, req, myMemberId)
            .then(response => setGameData(response?.data))
            .catch(handleSendMessageError);
    }

    const onTagImagesSubmit = (dataMap) => {
        if (dataMap.size === 0) {
            return;
        }
        // index based answers, for example shrek-dog-nanny.
        // then let's format the map this way
        let finalAnswer = "";
        dataMap.forEach((value) => {
            const answ = value.sort((a, b) => a.localeCompare(b))
            if (finalAnswer === "") {
                finalAnswer = finalAnswer.concat(answ);
            } else {
                finalAnswer = finalAnswer.concat("-" + answ);
            }
        })

        const req = {message: finalAnswer, data: gameData, attempt}
        gameApiV2.sendMessage(roomId, puzzleId, req, myMemberId)
            .then(response => {
                setGameData(response?.data);
            })
            .catch(handleSendMessageError);
    }

    const getTagImagesOptions = () => {
        if (tagImages == null || tagImages.action === null) {
            return [];
        }
        return tagImages.action?.options;
    }

    const results = puzzleStateResult?.data?.result;

    const onResultsClick = !!hasAllAnswers && !!results
        ? () => setShowResultsDialog(true)
        : null;

    const getSolution = () => {
        if (!myMemberId) {
            return null;
        }
        const myGameplay = gameData?.puzzle?.gameplayByMemberId[myMemberId];
        if (!myGameplay) {
            return null;
        }
        return myGameplay.hint.find(it => it.type === 'SOLUTION')?.value;
    }

    return (
        <>
            <CountdownOverlay  />
            <TagImagesDialog
                tagImages={tagImages}
                options={getTagImagesOptions()}
                closeDialog={() => setTagImages(null)}
                onSubmit={onTagImagesSubmit}
            />
            {tagOptions && (
                <TagOptionsDialog
                    tagOptions={tagOptions.message}
                    availableOptions={tagOptions.values}
                    open={showTagOptions}
                    closeDialog={() => setShowTagOptions(false)}
                    onSubmit={onTagOptionsSubmit}
                />
            )}
            {orderTagOptions && (
                <TagOptionsDialog
                    tagOptions={orderTagOptions?.message}
                    availableOptions={orderTagOptions?.values}
                    open={showOrderTagOptions}
                    closeDialog={() => setShowOrderTagOptions(false)}
                    onSubmit={onOrderTagOptionsSubmit}
                    isOrdered
                />
            )}
            {dragFillUniqueOptions && (
                <FillGapsDialog
                    message={dragFillUniqueOptions?.message}
                    availableOptions={dragFillUniqueOptions?.values}
                    open={showDragFillUniqueDialog}
                    onClose={() => setShowDragFillUniqueDialog(false)}
                    onSubmit={onDragFillUniqueSubmit}
                    unique
                />
            )}
            {dragFillOptions && (
                <FillGapsDialog
                    message={dragFillOptions?.message}
                    availableOptions={dragFillOptions?.values}
                    open={showDragFillDialog}
                    onClose={() => setShowDragFillDialog(false)}
                    onSubmit={onDragFillSubmit}
                    unique={false}
                />
            )}
            <ResultsDialog
                results={results}
                open={showResultsDialog}
                onClose={() => setShowResultsDialog(false)}
                myName={playerNames?.myName}
                gameName={tPuzzle(gameName)}
                puzzleName={tPuzzle(puzzleName)}
                scoreCapForDifficultyChange={gameData?.puzzle?.scoreCapForDifficultyChange}
                solution={getSolution()}
                memberId={myMemberId}

            />
            {gameData && matchReady && (
                <ChatRoot
                    pendingMessage={pendingMessage}
                    sendError={sendError}
                    onSend={sendAnswer}
                    onTagImages={onTagImages}
                    onTagOptions={onTagOptions}
                    onOrderTagOptions={onOrderTagOptions}
                    onDragFillUnique={onDragFillUnique}
                    onDragFill={onDragFill}
                    onResultsClick={onResultsClick}
                />
            )}
        </>
    )
}
