// ResultsDialog.js
import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {Alert, alpha, Stack, Table, TableBody, TableCell, TableContainer, TableRow,} from "@mui/material";
import useIsGuest from "../../hooks/useIsGuest";
import {useTranslation} from "react-i18next";
import ShareBox from "../ShareBox";

import {useRoomContext} from "../../context/RoomContextProvider";
import {DIFFICULTIES_OPTIONS} from "../../game/Game";
import SolutionExplanationModal from "./SolutionExplanationModal";
import {usePuzzleContext} from "../../context/PuzzleContextProvider";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ResultsDialog(
    {
        results,
        open,
        onClose,
        gameName,
        puzzleName,
        scoreCapForDifficultyChange = null,
        initShowGrandResult = false,
        solution,
        memberId,
        myName
    }
) {
    const {t: tGeneral } = useTranslation()
    const {t} = useTranslation("resultsDialog");
    const {t: tPuzzle} = useTranslation("puzzle");
    const {t: tContinuePuzzleVideo} = useTranslation("continuePuzzleVideo");
    const {roomId} = useRoomContext();
    const { isMyMemberKicked } = usePuzzleContext();
    const isCompleted = results?.nextPuzzleLinkByMemberId === null

    const {isGuest} = useIsGuest(roomId);
    const [showUnlockPuzzle] = useState(true);
    const [showGrandResult, setShowGrandResult] = useState(initShowGrandResult);
    const [showSolution, setShowSolution] = useState(false);

    const handleClose = () => {
        onClose();
    };

    const onSubmitClick = () => {
        onContinueClick();
    };

    const redirectToNextPuzzle = (nextPuzzleLink) => {
        window.location.href = nextPuzzleLink;
    }

    const isScoreCapForDifficultyReached = () => {
        if (showGrandResult) {
            return false;
        }
        const score = results?.score?.score;

        if (!scoreCapForDifficultyChange || !score) {
            return false;
        }

        if (parseInt(score, 10) >= parseInt(scoreCapForDifficultyChange, 10)) {
            // need to try harder to reach score cap
            return true;
        }
        return false;
    }

    const getDifficultyFromUrl = (url) => {
        return url.searchParams.has('difficulty') ? url.searchParams.get('difficulty') : null;
    }
    const checkScoreCapForDifficultyChangeAndRedirect = (nextPuzzleLink) => {
        if (!isScoreCapForDifficultyReached()) {
            redirectToNextPuzzle(nextPuzzleLink);
            return;
        }
        // Getting the value of 'difficulty' parameter
        const url = new URL(nextPuzzleLink);
        const difficultyParam = getDifficultyFromUrl(url);
        if (!difficultyParam) {
            redirectToNextPuzzle(nextPuzzleLink);
            return;
        }
        if (difficultyParam === DIFFICULTIES_OPTIONS.STANDARD) {
            url.searchParams.set('difficulty', DIFFICULTIES_OPTIONS.STANDARD);
            window.location.href = url.toString();
            return;
        }
        window.location.href = nextPuzzleLink;
    };

    const onContinueClick = () => {
        if (isCompleted && !showGrandResult) {
            setShowGrandResult(true);
            return;
        }
        if (isCompleted && showGrandResult) {
            handleClose();
            window.location.href = results?.statsLinkByMemberId?.[memberId];
            return;
        }
        handleClose();
        const puzzleLink = results?.nextPuzzleLinkByMemberId?.[memberId]
        checkScoreCapForDifficultyChangeAndRedirect(puzzleLink);
    };

    function createData(name, result) {
        return {name, result};
    }

    const rows = () => {
        return [
            createData(t("timeSpent"), results?.timeSpent),
        ];
    };

    const completedRows = () => {
        const {gameStatsByMemberId} = results;
        const gameStats = gameStatsByMemberId?.[memberId];
        return [
            createData(t("timeSpent"), gameStats?.totalMinutes + "min"),
        ];
    };

    const getRows = () => {
        if (!showGrandResult) {
            return rows();
        }
        return completedRows();
    };

    const getContinueText = (isGameCompleted) => {
        if (isGameCompleted) {
            return t("congratulationsText");
        }
        return tContinuePuzzleVideo(puzzleName);
    };

    const renderCompletedText = () => {
        const text = getContinueText(showGrandResult);
        return (
            <Stack p={2}>
                <Typography variant="p" sx={{color: 'white', fontSize: 14}}>{text}</Typography>
            </Stack>
        )
    };

    const getButtonText = () => {
        if (showGrandResult) {
            return t("goToMenu");
        }
        return t("continueButton");
    };

    const getScoreTitle = () => {
        if (showGrandResult) {
            return t("grandTotalResults");
        }
        return t("totalScore");
    };

    const getScore = () => {
        if (showGrandResult) {
            const {gameStatsByMemberId} = results;
            const gameStats = gameStatsByMemberId?.[memberId];
            return Math.max(0, gameStats?.totalScore || 0);
        }
        return results?.wasSolutionHintUsed
            ? '-'
            : Math.max(0, results?.score?.score || 0);
    }

    return (
        <>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                PaperProps={{sx: {borderRadius: 0}}}
                sx={{
                    '.MuiDialog-container .MuiPaper-root': {
                        borderRadius: 0,
                    }
                }}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            {t("resultsTitle")}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Paper height='100%' sx={{
                    padding: 1,
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column'
                }}>
                    <Stack m={1} gap={2}>
                        {!showGrandResult && results?.score?.comment && (
                            <Alert severity="success" sx={{'&.MuiPaper-root': {borderRadius: '8px !important'}}}>
                                {tPuzzle(results?.score?.comment)}
                            </Alert>
                        )}
                        <Stack sx={{color: 'white'}} alignItems="center">
                            <Typography sx={{fontSize: 18, fontWeight: 600}}>
                                {getScoreTitle()}
                            </Typography>
                            <Typography sx={{fontWeight: 'bold', fontSize: 32, color: '#FF5757'}}>
                                {getScore()}
                            </Typography>
                            {results?.wasSolutionHintUsed && (
                                <Typography sx={{fontWeight: 'normal', fontSize: 16, color: '#FF5757'}}>
                                    {t('solutionHintUsed')}
                                </Typography>
                            )}
                        </Stack>
                        <TableContainer sx={{marginBottom: 1, marginTop: 1}}>
                            <Table>
                                <TableBody>
                                    {getRows().map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell
                                                sx={{
                                                    border: 'none',
                                                    color: alpha('#fff', 0.6),
                                                    fontSize: '16px'
                                                }}
                                            >
                                                {row.name}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={{
                                                    border: 'none',
                                                    color: '#FF5757',
                                                    fontSize: '16px',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {row.result}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {isMyMemberKicked && (
                            <Alert severity="error">
                                {tGeneral('kickedOut')}
                            </Alert>
                        )}
                        {!!solution && (
                            <Button
                                fullWidth
                                size="large"
                                variant="outlined"
                                color="success"
                                onClick={() => setShowSolution(true)}
                            >
                                {t('solutionExplanation.button')}
                            </Button>
                        )}
                        {isScoreCapForDifficultyReached() && (
                            <Alert
                                severity="info"
                                sx={{'&.MuiPaper-root': {borderRadius: '8px !important'}}}
                            >
                                {t('resultsScoreDifficultyCap')}
                            </Alert>
                        )}
                        {!isMyMemberKicked && renderCompletedText()}
                    </Stack>
                    {!isMyMemberKicked && (
                        <Box m={1}>
                            {!isGuest && !showUnlockPuzzle && <Alert variant="outlined" severity="warning" sx={{mb: 1}}>
                                <Typography variant="p" sx={{color: 'white', fontSize: 12}}>
                                    {t("waitMessage")}
                                </Typography>
                            </Alert>}
                            {!initShowGrandResult && (
                                <Button
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    color="success"
                                    onClick={onSubmitClick}
                                >
                                    {getButtonText()}
                                </Button>)}
                        </Box>
                    )}
                    <ShareBox
                        myName={myName}
                        results={results}
                        puzzleName={puzzleName}
                        gameName={gameName}
                        totalScore={results?.gameStats?.totalScore ?? results?.gameStatsByMemberId?.[memberId]?.totalScore}
                    />
                </Paper>
                <SolutionExplanationModal
                    open={showSolution}
                    onClose={() => setShowSolution(false)}
                    solution={solution}
                />
            </Dialog>
        </>
    );
}
