import * as React from 'react';
import {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import {Button, Stack, styled} from "@mui/material";
import TaggerCarousel from "../TagOptionsCarousel";
import PaperSegment from "../../PaperSegment";
import AnswerSubmitButton from "../AnswerSubmitButton";
import AnswerDialogHeader from "../AnswerDialogHeader";
import AnswerDialogPaper from "../AnswerDialogPaper";
import {Trans, useTranslation} from "react-i18next";
import dragDropIcon from '../../../icons/IconDragDrop.png';
import TranslationClickableGap from "./TranslationClickableGap";
import OptionsDrawer from "./OptionsDrawer";
import {isEmpty, omit} from "lodash";
import CancelIcon from '@mui/icons-material/Cancel';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogSx = styled(Dialog)`
    .MuiDialog-container .MuiPaper-root {
        border-radius: 0;
        background-color: #e9e4de !important;
    }
`

export default function FillGapsDialog(
    {
        message,
        open,
        availableOptions,
        onClose,
        onSubmit,
        unique,
    }
) {
    const {t} = useTranslation("fillGapsDialog");
    const {t: transPuzzle} = useTranslation("puzzle");

    const handleClose = () => {
        onClose();
    };

    const onSubmitClick = () => {
        const sortedGapFills = Object.entries(filledGaps).sort((a, b) => {
            const keyA = a[0];
            const keyB = b[0];

            const numA = parseInt(keyA.split('-')[1], 10);
            const numB = parseInt(keyB.split('-')[1], 10);

            return numA - numB;
        });

        const translatedAnswers = sortedGapFills
            .map(([, value]) => value.label)
            .map(transPuzzle);
        onClose();
        onSubmit(translatedAnswers);
    };

    const renderGallery = () => {
        if (message?.action?.media?.length > 0) {
            return <TaggerCarousel images={message?.action?.media}/>
        }
    }

    const gapCount = message?.action?.answer[0]?.split('-')?.length;
    const initialGapsState = {};
    const [filledGaps, setFilledGaps] = useState(initialGapsState);
    const [activeGap, setActiveGap] = useState(null);

    const onlyNotUsed = (option) => {
        if (unique) {
            return !Object
                .values(filledGaps)
                .some(it => it.id === option.id && it.id !== filledGaps[activeGap]?.id);
        }
        return true;
    }

    const options = availableOptions
        ?.map((optionLabel, index) => ({id: index, label: transPuzzle(optionLabel)}))
        .filter(onlyNotUsed)
        .sort();

    const fillTheGap = (gapId, option) => {
        setFilledGaps(prev => ({...prev, [gapId]: option}));
        setActiveGap(null);
    }

    const clearTheGap = (gapId) => {
        setFilledGaps(prev => omit(prev, gapId));
    }

    const clearGaps = () => {
        setFilledGaps(initialGapsState)
    }


    return (
        <DialogSx fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AnswerDialogHeader onBack={handleClose}/>
            <AnswerDialogPaper>
                <Stack mb={5} mx={2}>
                    {renderGallery()}
                </Stack>

                <Stack px={2}>

                    <PaperSegment mt={1}>
                        <Typography variant="h5" component="div" fontWeight="bold">
                            {t('textChallengeTitle')}
                        </Typography>
                        <Stack
                            direction="row"
                            gap={2}
                            alignItems="center"
                            my={1}
                            px={2}
                            py={1}
                            bgcolor={"#dae2f3"}
                            borderRadius={1}
                        >
                            <img src={dragDropIcon} width={24} style={{objectFit: 'contain'}} alt={"drag'n'drop"}/>
                            <Typography component="div" fontSize={14} sx={{opacity: 0.7}}>
                                {t('textChallengeDescription')}
                            </Typography>
                        </Stack>
                        <Stack sx={{p: {textIndent: '20px', marginTop: 0}, span: {textIndent: 0}}}>
                            <Typography lineHeight={1.75} sx={{fontSize: 16}} component="div">
                                <Trans
                                    i18nKey={transPuzzle(message?.action?.text)}
                                    components={{
                                        gap: (
                                            <TranslationClickableGap
                                                filledGaps={filledGaps}
                                                transPuzzle={transPuzzle}
                                                onActivate={setActiveGap}
                                            />
                                        )
                                    }}
                                />
                            </Typography>
                        </Stack>
                        <Stack direction="row" mt={2} gap={1}>
                            <Button
                                variant="contained"
                                startIcon={<CancelIcon />}
                                sx={{
                                    minWidth: 110,
                                    "&.Mui-disabled": {
                                        backgroundColor: "lightgray !important",
                                        color: "gray",
                                        opacity: 0.6,
                                    },
                                }}
                                onClick={clearGaps}
                                disabled={isEmpty(filledGaps)}
                            >
                                <Typography noWrap>
                                    Clear
                                </Typography>
                            </Button>
                            <AnswerSubmitButton
                                onClick={onSubmitClick}
                                disabled={gapCount > Object.values(filledGaps).length}
                            >
                                {t('submitButton')}
                            </AnswerSubmitButton>
                        </Stack>
                    </PaperSegment>
                </Stack>
            </AnswerDialogPaper>
            <OptionsDrawer
                open={!!activeGap}
                activeOption={filledGaps[activeGap]}
                onClose={() => setActiveGap(null)}
                title={t('availableOptions', {count: availableOptions?.length})}
                options={options}
                transPuzzle={transPuzzle}
                onSelect={(option) => fillTheGap(activeGap, option)}
                onClear={() => clearTheGap(activeGap)}
            />
        </DialogSx>
    );
}
