import React, {useEffect} from 'react';
import {alpha, Stack, Typography} from '@mui/material';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {deleteRoomInfo} from "../util/storageUtil";

export default function MemberNotFoundPage() {
    const { t } = useTranslation('youHaveBeenKickedModal');
    const { roomId } = useParams();

    useEffect(() => {
        if (roomId) {
            deleteRoomInfo(roomId);
        }
    }, [roomId]);


    return (
        <Stack
            py={3}
            px={2}
            sx={{
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                minHeight: '100vh',
            }}
            gap={2}
        >
            <Typography variant="h3" component="h1" color="white">
                {t('title')}
            </Typography>
            <Typography id="modal-problem-body-1" variant="body6" sx={{color: alpha('#fff', 0.6)}}>
                {t('description')}
            </Typography>
        </Stack>
    );
}
