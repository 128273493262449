import {Avatar, Message, MessageGroup} from "@chatscope/chat-ui-kit-react";
import {getTitle, resolveMsgType} from "../ChatHelper";
import React from "react";
import {useTranslation} from "react-i18next";
import {getDetectiveAvatar} from "../../utils/gameUtils";

const ImageMessage = ({m, gameData, renderTitle, images, onClick, playerNames}) => {
    const { t: transPuzzle } = useTranslation('puzzle');

    return (
        <MessageGroup direction="incoming" sender={m.sender} avatarPosition="cl">
            {m?.sender === 'game' && <Avatar src={getDetectiveAvatar(gameData)} name={m.sender}/>}
            <MessageGroup.Messages>
                { m?.title?.length > 0 && !!renderTitle ? <Message model={{message: getTitle(m.title, transPuzzle, playerNames)}}/> : null }
                { images.map((image, index) => (
                    <Message key={image?.src} model={{type: resolveMsgType(m), sender: m.sender}} style={{
                        maxWidth: '70%'}}>
                        <Message.CustomContent>
                            <img src={image?.src}
                                 alt="puzzle"
                                 onClick={() => onClick(index)}
                                 style={{
                                     maxWidth: '100%',
                                     height: 'auto',
                                     padding: 0,
                                     margin: 0,
                                 }}
                            />
                        </Message.CustomContent>
                    </Message>
                ))}
            </MessageGroup.Messages>
        </MessageGroup>
    )
}

export default ImageMessage
