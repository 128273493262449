import {coreClient} from './config';

const versionPrefix = '/v2/game';

const gameApiV2 = {
    joinRoomAsNewGuestMember: (roomId, name, email) => coreClient
        .post(`${versionPrefix}/rooms/${roomId}/join?isGuest=${true}`, { memberId: null, name, email })
        .then(({data}) => data),
    joinRoomAsHost: (roomId) => coreClient
        .post(`${versionPrefix}/rooms/${roomId}/join?isGuest=${false}`, { memberId: null, name: null })
        .then(({data}) => data),
    joinPuzzle: (roomId, puzzleId, memberId) => coreClient
        .post(`${versionPrefix}/rooms/${roomId}/puzzles/${puzzleId}/join`, { memberId })
        .then((res) => res?.data),
    startPuzzle: (roomId, puzzleId) => coreClient
        .post(`${versionPrefix}/rooms/${roomId}/puzzles/${puzzleId}/start`)
        .then(({data}) => data),
    skipStartCountdown: (roomId, puzzleId) => coreClient
        .post(`${versionPrefix}/rooms/${roomId}/puzzles/${puzzleId}/start/skip-countdown`)
        .then(({data}) => data),
    retryPuzzle: (roomId, puzzleId) => coreClient
        .post(`${versionPrefix}/rooms/${roomId}/puzzles/${puzzleId}/retry`)
        .then(({data}) => data),
    restartPuzzle: (roomId, puzzleId) => coreClient
        .post(`${versionPrefix}/rooms/${roomId}/puzzles/${puzzleId}/restart`)
        .then(({data}) => data),
    hardRestartPuzzle: (roomId, puzzleId) => coreClient
        .post(`${versionPrefix}/rooms/${roomId}/puzzles/${puzzleId}/hard-restart`)
        .then(({data}) => data),
    changePuzzleDifficulty: (roomId, puzzleId, difficulty) => coreClient
        .post(`${versionPrefix}/rooms/${roomId}/puzzles/${puzzleId}/difficulty`, { difficulty })
        .then(({data}) => data),
    fetchPuzzleState: (roomId, puzzleId) => coreClient
        .get(`${versionPrefix}/rooms/${roomId}/puzzles/${puzzleId}`)
        .then(({data}) => data),
    sendMessage: (roomId, puzzleId, body, memberId) => coreClient
        .post(`${versionPrefix}/rooms/${roomId}/puzzles/${puzzleId}/answers?memberId=${memberId}`, body)
        .then((response) => response),
    getHint: (roomId, puzzleId, memberId, type) => coreClient
        .get(`${versionPrefix}/rooms/${roomId}/puzzles/${puzzleId}/hint?memberId=${memberId}&type=${type}`)
        .then(({data}) => data),
    sendTimerMessage: (roomId, puzzleId, body, memberId) => coreClient
        .post(`${versionPrefix}/rooms/${roomId}/puzzles/${puzzleId}/timer?memberId=${memberId}`, body)
        .then((response) => response),
    toggleTimerMessages: (roomId) => coreClient
        .post(`${versionPrefix}/rooms/${roomId}/timer/toggle`)
        .then((response) => response),
    kickMember: (roomId, memberId, puzzleId) => coreClient
        .post(`${versionPrefix}/rooms/${roomId}/puzzles/${puzzleId}/members/${memberId}/kick`)
        .then(({data}) => data),
};
export default gameApiV2;
