import Typography from "@mui/material/Typography";
import {alpha, Button} from "@mui/material";
import Divider from "@mui/material/Divider";
import * as React from "react";
import gameApiV2 from "../../api/gameApiV2";
import {resolveErrorMessage} from "../../utils/errors";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useRoomContext} from "../../context/RoomContextProvider";
import {usePuzzleContext} from "../../context/PuzzleContextProvider";

export default function ProblemStepOne({ onProceed, withRestart }) {
    const { roomId } = useRoomContext();
    const { puzzleId } = usePuzzleContext();
    const { t } = useTranslation('problemModal')
    const navigate = useNavigate();
    const onPuzzleRestart = () => {
        gameApiV2.hardRestartPuzzle(roomId, puzzleId)
            .then(() => {
                onProceed();
            }).catch(({ response }) => {
            navigate('/error', { state: { title: resolveErrorMessage(response) }})
        })
    }

    return (
        <>
            <Typography id="modal-problem-body-1" variant="body6" sx={{color: alpha('#fff', 0.6)}}>
                {t('sorry')}
            </Typography>
            <Divider />
            <Typography id="modal-problem-body-1-1" variant="body6" sx={{color: alpha('#fff', 0.6), paddingTop: 2}}>
                {t('clickContinue')}
                <br />
                <br />
                <b>{t('areYouSure')}</b>
            </Typography>

            <Button size="large" variant="contained" sx={{width: '100%', marginTop: 4}} onClick={onPuzzleRestart}>
                {t('reset')}
            </Button>
        </>
    )
}