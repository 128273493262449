// const isGuestName = "isGuest";

import {isNaN, isNil} from "lodash";

const STORAGE_KEY = {
    ROOM_ID: 'roomId',
    IS_GUEST: 'isGuest',
    MEMBER_ID: 'memberId',
    UPDATED_AT: 'updatedAt',
    IS_KICKED: 'isKicked'
}

const getRoomInfo = (roomId) => {
    return JSON.parse(sessionStorage.getItem(roomId));
}

export const getLatestRoomId = () => {
    let latestRoomId = null;
    let latestUpdatedAt = 0;

    for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        const data = JSON.parse(sessionStorage.getItem(key));

        // Parse `updatedAt` into a timestamp for comparison
        const updatedAtTimestamp = new Date(data.updatedAt).getTime();

        // Compare and update the latest roomId
        if (updatedAtTimestamp > latestUpdatedAt) {
            latestUpdatedAt = updatedAtTimestamp;
            latestRoomId = key;
        }
    }

    return latestRoomId;
};

export const saveRoomInfo = (roomId, newInfo = {}) => {
    const currentRoomInfo = getRoomInfo(roomId) ?? {};
    const newRoomInfo = {...currentRoomInfo, ...newInfo, [STORAGE_KEY.UPDATED_AT]: new Date() };
    sessionStorage.setItem(roomId, JSON.stringify(newRoomInfo))
}

export const deleteRoomInfo = (roomId) => {
    sessionStorage.removeItem(roomId)
}

export const setGuestStore = (roomId, isGuest) => {
    if (isNil(isGuest) || isNil(roomId)) {
        return;
    }

    saveRoomInfo(roomId, { [STORAGE_KEY.IS_GUEST]: isGuest })
}

export const isGuestFromStore = (roomId) => {
    const roomInfo = getRoomInfo(roomId)

    if (!roomInfo) {
        return null;
    }
    return roomInfo.isGuest === 'true';
}

export const getMemberIdFromStore = (roomId) => {
    const roomInfo = getRoomInfo(roomId)

    if (!roomInfo || !roomInfo?.memberId || isNaN(Number(roomInfo?.memberId)) || roomInfo.isKicked) {
        return null;
    }
    return Number(roomInfo.memberId);
}

export const saveMemberIdToStore = (roomId, memberId) => {
    return saveRoomInfo(roomId, { [STORAGE_KEY.MEMBER_ID]: memberId.toString()});
}
