import {Button, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InviteLinkBox from "../InviteLinkBox";

const WaitingRoomInviteBoxView = ({onClose}) => {
    const {t} = useTranslation('puzzleWaitingStartModal')

    return (
        <Stack gap={2}>
            <Stack direction="row" justifyContent="space-between" position="relative">
                <Typography variant="h6" component="h2">
                    {t('titleInviteMore')}
                </Typography>
                <IconButton sx={{position: 'absolute', top: -30, right: -30}} onClick={onClose}>
                    <CloseIcon sx={{color: 'white'}}/>
                </IconButton>
            </Stack>
            <InviteLinkBox/>
            <Button variant="contained" onClick={onClose}>
                OK
            </Button>
        </Stack>
    )
}

export default WaitingRoomInviteBoxView;