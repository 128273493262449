import {Button, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import PersonIcon from '@mui/icons-material/Person';
import {useTranslation} from "react-i18next";
import * as React from "react";
import {useProblemContext} from "../../context/ProblemContextProvider";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

const GameSeat = (
    {
        player,
        myMemberId,
        renderEndComponent,
        showHelp,
        onInvite
    }
) => {
    const {t} = useTranslation('gameSeat');
    const isMe = Number(myMemberId) === Number(player?.memberId);
    const {setPlayerProblemHelpActive} = useProblemContext();
    const showInvite = !player && onInvite;

    const renderYouTag = () => {
        return (
            <Stack
                py={0.25}
                px={1}
                sx={{
                    backgroundColor: '#ff4d4d',
                    left: -20,
                    top: -8.
                }}
                ml={0.5}
                borderRadius={2}
                position="absolute"
            >
                <Typography fontSize={11} color="white" fontWeight="bold">
                    {t('youTag')}
                </Typography>
            </Stack>
        )
    }
    const renderIndicator = (isEmpty, isPrevious) => {
        return (
            <Stack
                sx={{backgroundColor: isEmpty ? '#253149' : "#7556f4", opacity: isPrevious ? 0.4 : 1}}
                borderRadius="50%"
                minHeight={46}
                minWidth={46}
                maxHeight={46}
                maxWidth={46}
                alignItems="center"
                justifyContent="center"
                position="relative"
            >
                {!isEmpty && <PersonIcon/>}
                {showInvite && <PersonAddAltIcon sx={{color: '#454f65'}}/>}
                {isMe && renderYouTag()}
            </Stack>
        )
    }

    const getSubtitle = (player) => {
        if (player.isPrevious) {
            return t('waitingForPlayer')
        }
        return player.isGuest
            ? t('guestSubtitle')
            : t('hostSubtitle')
    }

    const openPlayerHelp = () => {
        setPlayerProblemHelpActive(player);
    }

    const renderPlayerDetails = (player) => {
        if (!player) {
            return (
                <Stack sx={{opacity: 0.8}}>
                    <Typography color="#454f65" fontWeight="normal">
                        {t('emptyTitle')}
                    </Typography>
                    <Typography color="#454f65" fontSize={12} sx={{opacity: 0.6}}>
                        {t('emptySubtitle')}
                    </Typography>
                </Stack>
            )
        }

        return (
            <Stack
                direction="row"
                gap={1}
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                position="relative"
            >
                <Stack direction="row" gap={1} alignItems="flex-start">
                    <Stack>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Typography fontWeight="normal" color={player.isPrevious ? "#454f65" : undefined}>
                                {player.name}
                            </Typography>
                        </Stack>
                        <Typography fontSize={12} sx={{opacity: 0.6}} color={player.isPrevious ? "#454f65" : undefined}>
                            {getSubtitle(player)}
                        </Typography>
                    </Stack>
                </Stack>
                {renderEndComponent && renderEndComponent(player)}
                {player.isPrevious && showHelp && (
                    <Button variant="outlined" onClick={openPlayerHelp}>
                        <Typography fontSize={14}>
                            {t('help')}
                        </Typography>
                    </Button>
                )}
            </Stack>
        )
    }

    return (
        <Stack
            direction="row"
            gap={1}
            alignItems="center"
            onClick={showInvite ? onInvite : undefined}
        >
            {renderIndicator(!player, player?.isPrevious)}
            {renderPlayerDetails(player)}
        </Stack>
    )
}

export default GameSeat;