import cc_icon from "../cc_icon.png";
import detective_steve from "../detective_steve.jpg";
import detective_max from "../detective_max.png";
const detective = {
    intro: {
        name: 'CouchClues',
        avatar: cc_icon,
    },
    "translate.game.toxic_case": {
        name: 'Steve',
        avatar: detective_steve,
    },
    "translate.game.paradise": {
        name: 'Max',
        avatar: detective_max,
    }
}


export const getDetectiveAvatar = (gameData) => {
    if (!gameData?.puzzle) {
        return null;
    }
    if (gameData?.puzzle?.isTestPuzzle) {
        return detective.intro.avatar;
    }
    return detective[gameData.gameName].avatar;
}
export const getDetectiveName = (gameData) => {
    if (!gameData?.puzzle) {
        return null;
    }
    if (gameData?.puzzle?.isTestPuzzle) {
        return detective.intro.name;
    }
    return detective[gameData.gameName].name;
}