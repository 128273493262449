import {Stack} from "@mui/material";
import * as React from "react";
import GameSeat from "../game-seats/GameSeat";
import {useRoomContext} from "../../context/RoomContextProvider";
import {usePuzzleContext} from "../../context/PuzzleContextProvider";
import {isEmpty} from "lodash";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';

const PuzzleFinishList = () => {
    const { myMemberId } = useRoomContext();
    const { puzzleStateResult } = usePuzzleContext();
    const { data } = puzzleStateResult;

    if (!data) {
        return null;
    }

    const seats = [...Array(data.maxPlayers)]
        .map((it, index) => data.players[index])

    const sortedSeats = seats.sort((a, b) => {
        // Handle undefined objects and move them to the end
        if (!a || !b) {
            return a ? -1 : 1;
        }

        // Sort by isGuest (false should come first)
        if (a.isGuest !== b.isGuest) {
            return a.isGuest ? 1 : -1;
        }

        // Sort by name alphabetically (if isGuest is the same)
        if (a.name && b.name) {
            return a.name.localeCompare(b.name);
        }

        // If one name is undefined, the defined one goes first
        if (!a.name) return 1;
        if (!b.name) return -1;

        return 0;
    });

    const renderAnswer = (playerData) => {
        return playerData?.providedAnswers.some(it => it.attempt === data?.attempts)
            ? <CheckCircleIcon sx={{ color: '#89c433' }} />
            : <HistoryToggleOffIcon sx={{ color: '#525252' }} />
    }

    return (
        <Stack gap={1}>
            {sortedSeats.map((it, index) => [
                <GameSeat
                    player={it}
                    key={index}
                    myMemberId={myMemberId}
                    renderEndComponent={renderAnswer}
                />
            ])}
        </Stack>
    )
}

export default PuzzleFinishList;