import Typography from "@mui/material/Typography";
import {alpha, Button} from "@mui/material";
import * as React from "react";
import {useTranslation} from "react-i18next";
import InviteLinkBox from "../InviteLinkBox";

export default function ProblemStepTwo({ onProceed }) {
    const {t} = useTranslation('problemModal')

    return (
        <>
            <Typography id="modal-problem-body-2" variant="body6" sx={{color: alpha('#fff', 0.6)}}>
                {t('guestNeedRejoin')}
            </Typography>
            <InviteLinkBox/>
            <Button size="large" variant="contained" sx={{width: '100%', marginTop: 4}} onClick={onProceed}>
                {t('inviteContinue')}
            </Button>
        </>
    )
}