
export const getInviteLink = (roomId, puzzleId) => {
    let url = window.location.protocol + '//' + window.location.host + "/";
    return url.concat(roomId).concat("/").concat(puzzleId).concat("/guest");
}

export const getMemberInviteLink = (roomId, puzzleId, memberId) => {
    let url = window.location.protocol + '//' + window.location.host + "/";
    return url.concat(roomId).concat("/").concat(puzzleId).concat("/").concat(memberId);
}

export const delay = ms => new Promise(res => setTimeout(res, ms));
