const enGame=  ({
    language: "Language",
    languageEn: "English",
    languageNl: "Nederlands",
    continue: "CONTINUE!",
    playNow: "Play Now",
    seeResults: "See Results",
    selectLanguage: "Please select the language",
    go: "GO!",
    results: "Results",
    completed: "Completed:",
    noPuzzles: "No puzzles available. Please visit the website to buy a game",
    kickedAlert: "You are no longer part of this game. Please ask the host to invite you if you are ready to rejoin.",
    kickedOut: "You have been removed from this puzzle.",
    typeToAnswer: "Type answer here",
    clickToTag: "click to answer",
    clickToTagAnswer: "Click to tag answers",
    clickToTagAnswerInOrder: "Tag answers in the correct order",
    clickToDragFill: "Click to fill in the gaps",
    backToChat: "Back to chat",
    chatWith: "Chatting with",
    difficulty: "Difficulty",
    copyLink: "Copy this link and share it with your friends:",
    copyLinkLabel: "Copy invite link",
    inviteFriend: "Invite a friend",
    about: "About",
    faq: "FAQ",
    shop: "Shop",
    waitForGo: "Please wait for the other player to press GO!",
    shareAlinkWithPlayer: "Share a link to invite another player",
    askPartnerToStart: "Ask your partner to start the game.",
    guestPlayerNotReady: "Guest player is not ready yet.",
    minimumPlayersText: "At least two players are required to play the game. {!isGuest ? ' Send the link to your friend and invite him to play ' : ''}",
    copyInviteLinkLabel: "Copy invite link",
    playerWaitingForPartner: "Wait for {{partnerName}} to enter the right solution. Maybe you can help?",
    difficultyLabel: "Difficulty",
    startButtonText: "Start the game",
    playButtonText: "Play!",
    shareLinkDialogTitle: "To start the game, share the link below with another player",
    startGameDialogTitle: "Let's solve it together!",
    yourNameLabel: "Your name",
    getStartText: "Start the game",
    errorGoHome: "Go home",
    error: "Error",
    notExists: "The game you are looking for does not exist",
    buyAnother: "You need to buy another chapter in order to play this game",
    youDontHave: "You do not have this chapter",
    getPuzzle: "Buy chapter",
    steveIsTyping: "Typing...",
})

export default enGame;