import * as React from 'react';
import {useEffect} from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {modalStyle} from "../../style/modalStyle";
import {Button, Stack} from "@mui/material";
import {usePuzzleContext} from "../../context/PuzzleContextProvider";
import {useRoomContext} from "../../context/RoomContextProvider";
import {usePuzzleWaitingAnswersContext} from "../../context/PuzzleWaitingAnswersContextProvider";
import PuzzleFinishList from "./PuzzleFinishList";
import {isNil} from "lodash";
import {useTranslation} from "react-i18next";
import {useProblemContext} from "../../context/ProblemContextProvider";
import WaitingRoomProblemHelp from "../puzzle-waiting-start/WaitingRoomProblemHelp";

export default function PuzzleWaitingAnswersModal() {
    const {t} = useTranslation('puzzleWaitingAnswersModal')
    const {isHost, myMemberId} = useRoomContext();
    const {puzzleStateResult} = usePuzzleContext();
    const {hasAllAnswers, onTryAgain} = usePuzzleWaitingAnswersContext();
    const {data} = puzzleStateResult;

    const {
        waitingRoomProblemActive,
        setWaitingRoomProblemActive
    } = useProblemContext();

    useEffect(() => {
        if (!isNil(data) && hasAllAnswers && !data?.result && isHost) {
            onTryAgain();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, hasAllAnswers, isHost])

    // this is different from !!myAnswer, because isMyAnswerRegistered is only known after initial poll result
    // whereas !!myAnswer is known immediately after message submit
    // this is needed in order to avoid glitchy dialog when user is the last one to answer
    const isMyAnswerRegistered = !!data
        ?.players.find(player => player.memberId === myMemberId)
        ?.providedAnswers.some(answer => answer.attempt === data?.attempts);

    const renderWaitingRoom = () => (
        <>
            <Typography variant="h6" component="h2">
                {t('title')}
            </Typography>
            <PuzzleFinishList/>
            {isHost && (
                <Button
                    variant="outlined"
                    onClick={() => setWaitingRoomProblemActive(true)}
                >
                    {t('problem')}
                </Button>
            )}
        </>
    );

    const renderWaitingRoomProblemHelp = () => (
        <WaitingRoomProblemHelp
            onClose={() => setWaitingRoomProblemActive(false)}
            active={waitingRoomProblemActive}
        />
    )

    return (
        <Modal open={(isMyAnswerRegistered && !hasAllAnswers)}>
            <Stack sx={modalStyle} gap={2}>
                {[
                    {
                        takeIf: !!waitingRoomProblemActive,
                        content: renderWaitingRoomProblemHelp(),
                    },
                    {
                        takeIf: true,
                        content: renderWaitingRoom(),
                    }
                ].find(it => !!it.takeIf)?.content}
            </Stack>
        </Modal>
    );
}
