import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {useTranslation} from "react-i18next";
import ProblemStepOne from "./game-problem/ProblemStepOne";
import ProblemStepTwo from "./game-problem/ProblemStepTwo";
import ProblemStepThree from "./game-problem/ProblemStepThree";
import useProblemSteps from "./game-problem/hooks/useProblemSteps";
import {Stack} from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    boxShadow: 24,
    p: 4,
    backgroundColor: "#1B2436",
    color: 'white',
    borderRadius: '20px',
};

export default function ProblemModal({open, toggle}) {
    const {t} = useTranslation('problemModal')
    const {step, onProceed} = useProblemSteps(open);

    return (
        <Modal open={open} onClose={toggle}>
            <Box sx={style}>
                <Stack gap={2}>
                    <Typography variant="h6" component="h2">
                        {t('problem')}
                    </Typography>
                    {step === 1 && <ProblemStepOne onProceed={onProceed}/>}
                    {step === 2 && <ProblemStepTwo onProceed={onProceed}/>}
                    {step === 3 && <ProblemStepThree/>}
                </Stack>
            </Box>
        </Modal>
    );
}
