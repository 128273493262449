import {useLocation} from "react-router-dom";
import {isGuestFromStore, setGuestStore} from "../util/storageUtil";
import {useEffect} from "react";

const useIsGuest = (roomId) => {
    const location = useLocation();
    const isGuest = location.pathname.includes("/guest")
        || location.search.includes("isGuest=true");

    useEffect(() => {
        if (isGuestFromStore(roomId) !== isGuest) {
            setGuestStore(roomId, isGuest);
        }
    }, [roomId, isGuest]);

    return { isGuest, isHost: !isGuest };
};
export default useIsGuest;
