import {coreClient} from './config';

const gameApi = {
    getGame: (roomId, puzzleId, isGuest) => coreClient
            .get(`/game/${roomId}/${puzzleId}?isGuest=${isGuest}`)
            .then(({data}) => data),
    didAnyPlayerOpenedHint: (roomId, uuid, isGuest, hintType) => coreClient
            .get(`/game/${roomId}/${uuid}/hint/usage?isGuest=${isGuest}&type=${hintType}`)
            .then(({data}) => data),
};
export default gameApi;
