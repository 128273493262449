import {useEffect, useState} from "react";

export default function useProblemSteps(open){
    const [step, setStep] = useState(1);

    useEffect(() => {
        if (!open) {
            setStep(1);
        }
    }, [open]);

    const onProceed = () => {
        if (step === 3) {
            return;
        }
        setStep(prevOpen => prevOpen + 1)
    }

    return ({
        step,
        onProceed,
    })
}