const enPuzzleCommon = (
    {
        'translate.game.STANDARD': 'Standard',
        'translate.game.CHALLENGING': 'Challenging',

        'translate.game.hint.solution.label': "Solution",
        'translate.game.hint.explanation.label': "Explanation",
        'translate.game.hint.hints.label': "Hints",
        'translate.game.hint.hint1.label': "Hint 1",
        'translate.game.hint.hint2.label': "Hint 2",
        'translate.game.puzzle.timer.watch_video': "You are watching:",

        'translate.game.hint.explanation_minus_points.label': "Explanation",
        'translate.game.hint.hint1_minus_points.label': "Hint 1",
        'translate.game.hint.hint2_minus_points.label': "Hint 2",

        'translate.game.paradise': 'A Case in Paradise',
        'translate.game.toxic_case': 'A Toxic Case',

        'translate.game.messages.answers.waiting': "Thank you.",
        'translate.game.messages.answers.incorrectWhenOpponentCorrect': "Wait! {{partnerName}} sent me something as well...",
        'translate.game.messages.answers.allPlayersIncorrect': "I tried your solutions but they didnt work.",
        'translate.game.messages.answers.somePlayersIncorrect': "One or more answers seem correct, but not all. Discuss who needs to change their answer and submit again.",
        'translate.game.messages.answers.opponentCorrect': "Your solution didn't work, but the one {{params.correctPlayerNames}} sent me did!",
        'translate.game.messages.answers.diffAnswersImRightButWaiting': "What you said seems correct, but the answer from {{partnerName}} is incorrect. Help {{partnerName}} out and tell me to go when they're ready.",

        'translate.game.score.65': "Not bad! You scored better than 50% of players",
        'translate.game.score.75': "Nice! You had a better score than 75% of all other players",
        'translate.game.score.80': "Good score! You are in the top 20% of all players",
        'translate.game.score.85': "Very good score! You are in the top 15% for this challenge",
        'translate.game.score.90': "Great score! You are in the top 10% for this challenge.",
        'translate.game.score.95': "Almost perfect! You are in the top 5% for this puzzle!",
        'translate.game.score.100': "Congratulations! Less than 1% of players get a 100 for this puzzle.",
        'translate.game.score.140': "Not bad! Better than 50% of players on 'challenging' level",
        'translate.game.score.150': "Nice! You scored better than 55% of players on 'challenging' level",
        'translate.game.score.160': "Well done! You are in the top 25% of players (that play 'challenging' difficulty)",
        'translate.game.score.170': "Good score: You two in the top 20% (for 'challenging' difficulty)",
        'translate.game.score.180': "Very good score! You are in the top 15% for this challenge (on 'challenging' level)",
        'translate.game.score.190': "Great score! You are in the top 10% for this challenge (on 'challenging' level)",
        'translate.game.score.195': "Almost perfect! You two are in the top 5% for this puzzle!",
        'translate.game.score.200': "Amazing! Less than 1% of teams that play on challenging difficulty gets a 100 for this puzzle.",
        'translate.game.score.241': "Impressive! Better than 50% of players on 'crazy' difficulty",
        'translate.game.score.250': "Nice! You scored better then 55% of players on 'crazy' difficulty level",
        'translate.game.score.260': "Well done! You are in the top 25% for this challenge on 'crazy' difficulty level",
        'translate.game.score.270': "Good score: You two in the top 20% on 'crazy' difficulty level",
        'translate.game.score.280': "Very good score! You are in the top 15% for this challenge on 'crazy' difficulty level",
        'translate.game.score.290': "Great score! You are in the top 10% for this challenge on 'crazy' difficulty level",
        'translate.game.score.295': "You are the crazy geniuses within the crazy geniuses group! You are in the top 5%",
        'translate.game.score.300': "Wow! OK, there are 2 options. You are crazy geniuses, or you are cheating. But we don't want to tell crazy geniuses that they are cheating, so well done!",

        'translate.1_intro_simple.player.timer.message1': 'XHey player, maybe you need some help?',
        'translate.1_intro_simple.player.timer.message2': 'XIt is hard huh? Keep up!',
        'translate.1_intro_simple.player.timer.message3': 'XTick tack tick tack, maybe you need to use a hint?',
        'translate.1_intro_simple.guest.timer.message1': 'XHey guest, maybe you need some help?',
        'translate.1_intro_simple.player.hint.explanation': "XEvery puzzle has an explanation. An explanation will explain the puzzle if you have no idea what to do. For this intro 1 challenge, you and your partner have to find the same 3 digit number. To find it, both should click hint 1.",
        'translate.1_intro_simple.player.hint.hint2': "XThe first digit is a 9. Tell this to your partner. The game is all about talking to each other!. Now check Hint 2.",
        'translate.1_intro_simple.player.hint.solution': "XThe solution is 936. <br> Your first hint was that the first digit was a 9. The first hint for the other player was that the last digit was a 6. The second hint was the same for both of you: the second digit was a 3.",
        'translate.1_intro_simple.player.hint.hint3': "XDid your partner tell you the last number? The second digit is a 3. You could now solve the puzzle, or access the solution to be sure. If you click the solution in the real game, you will not gain any points for that challenge.",
        'translate.1_intro_simple.guest.hint.explanation': "XEvery puzzle has an explanation. An explanation will explain the puzzle if you have no idea what to do. For this intro 1 challenge, you and your partner have to find the same 3 digit number. To find it, both should click Hint 1.",
        'translate.1_intro_simple.guest.hint.hint2': "XThe last digit is a 6. Be sure to tell this to your partner. This game is all about talking to each other!. Now check Hint 2",
        'translate.1_intro_simple.guest.hint.solution': "XThe solution is 936. <br> Your first hint was that the last digit was a 6. The first hint for the other player was that the first digit was a 9. The second hint was the same for both of you: the second digit was a 3.",
        'translate.1_intro_simple.guest.hint.hint3': "XDid your partner tell you the first number? The second digit is a 3. You could now solve the puzzle, or access the solution to be sure. If you click the solution in the real game, you will not gain any points for that challenge.",

        'translate.1_intro_simple.player.message.title': "XHi {{playerName}}! Welcome to Couch Clues! To play this game, you will need to watch videos together on your TV (or any other screen). We recommend using Vimeo to stream the videos, but you can also watch them on Youtube. <a href=\"https://vimeo.com/showcase/11375804\" target=\"_blank\">Click this link to access Vimeo</a>, enter password 8712 and click 'Start Watching', select ENG subtitles and stream the video to your TV. For Youtube, open the Youtube app on your smart TV, search the 'CCACIPDevelopment' channel and find Playlist 'Couch Second Puzzle GT'. Then play the first video (Intro 1) and select ENG subtitles.",
        'translate.1_intro_simple.player.message2.title': "XYou will learn the game by playing 2 quick intro challenges with {{partnerName}}. Before you start, make sure that each player has a pen and paper ready. Follow the instructions on your television screen. Your goal is to send me a 3 digit code.",
        'translate.1_intro_simple.player.message2.errors': "XThat is not the right answer, but don't worry, this is just practice. Just a quick check. Are you both watching Youtube? Have you found the channel 'CouchClues' and the Playlist 'A Toxic Case'? And are you watching Intro 1? Then open the menu on the top right of your app and open the explanation, then hint 1 and then hint 2.",
        'translate.1_intro_simple.player.message3.title': "XWell done! Now you can pause the video and click the results button below. Your score is determined by the time, hints, wrong answers and if you used the solution. It makes no difference if one or both players use a hint and you can view the hints multiple times.",

        'translate.1_intro_simple.guest.message.title': "XHi {{playerName}}! Welcome to Couch Clues! To play this game, you will need to watch videos together on your TV (or any other screen). We recommend using Vimeo to stream the videos, but you can also watch them on Youtube. <a href=\"https://vimeo.com/showcase/11375804\" target=\"_blank\">Click this link to access Vimeo</a>, enter password 8712 and click 'Start Watching', select ENG subtitles and stream the video to your TV. For Youtube, open the Youtube app on your smart TV, search the 'CCACIPDevelopment' channel and find Playlist 'Couch Second Puzzle GT'. Then play the first video (Intro 1) and select ENG subtitles.",
        'translate.1_intro_simple.guest.message2.title': "XYou will learn the game by playing 2 quick intro challenges with {{partnerName}}. Before you start, make sure that each player has a pen and paper ready. Follow the instructions on your television screen. Your goal is to send me a 3 digit code.",
        'translate.1_intro_simple.guest.message2.errors': "XThat is not the right answer, but don't worry, this is just practice. Just a quick check. Are you both watching Youtube? Have you found the channel 'CouchClues' and the Playlist 'A Toxic Case'? And are you watching Intro 1? Then open the menu on the top right of your app and open the explanation, then hint 1 and then hint 2.",
        'translate.1_intro_simple.guest.message3.title': "XWell done! Now you can pause the video and click the results button below. Your score is determined by the time, hints, wrong answers and if you used the solution. It makes no difference if one or both players use a hint and you can view the hints multiple times.",

        'translate.1_intro_easy.player.hint.explanation': "XEvery puzzle has an explanation. An explanation will explain the puzzle if you have no idea what to do. For this intro 1 challenge, you and your partner have to find the same 3 digit number. To find it, both should click hint 1.",
        'translate.1_intro_easy.player.hint.hint2': "XThe first digit is a 9. Tell this to your partner. The game is all about talking to each other!. Now check Hint 2.",
        'translate.1_intro_easy.player.hint.solution': "XThe solution is 936. <br> Your first hint was that the first digit was a 9. The first hint for the other player was that the last digit was a 6. The second hint was the same for both of you: the second digit was a 3.",
        'translate.1_intro_easy.player.hint.hint3': "XDid your partner tell you the last number? The second digit is a 3. You could now solve the puzzle, or access the solution to be sure. If you click the solution in the real game, you will not gain any points for that challenge.",
        'translate.1_intro_easy.guest.hint.explanation': "XEvery puzzle has an explanation. An explanation will explain the puzzle if you have no idea what to do. For this intro 1 challenge, you and your partner have to find the same 3 digit number. To find it, both should click Hint 1.",
        'translate.1_intro_easy.guest.hint.hint2': "XThe last digit is a 6. Be sure to tell this to your partner. This game is all about talking to each other!. Now check Hint 2",
        'translate.1_intro_easy.guest.hint.solution': "XThe solution is 936. <br> Your first hint was that the last digit was a 6. The first hint for the other player was that the first digit was a 9. The second hint was the same for both of you: the second digit was a 3.",
        'translate.1_intro_easy.guest.hint.hint3': "XDid your partner tell you the first number? The second digit is a 3. You could now solve the puzzle, or access the solution to be sure. If you click the solution in the real game, you will not gain any points for that challenge.",

         'translate.1_intro_easy.player.message.title': "XHi {{playerName}}! Welcome to Couch Clues! To play this game, you will need to watch videos together on your TV (or any other screen). We recommend using Vimeo to stream the videos, but you can also watch them on Youtube. <a href=\"https://vimeo.com/showcase/11375804\" target=\"_blank\">Click this link to access Vimeo</a>, enter password 8712 and click 'Start Watching', select ENG subtitles and stream the video to your TV. For Youtube, open the Youtube app on your smart TV, search the 'CCACIPDevelopment' channel and find Playlist 'Couch Second Puzzle GT'. Then play the first video (Intro 1) and select ENG subtitles.",
        'translate.1_intro_easy.player.message2.title': "XYou will learn the game by playing 2 quick intro challenges with {{partnerName}}. Before you start, make sure that each player has a pen and paper ready. Follow the instructions on your television screen. Your goal is to send me a 3 digit code.",
        'translate.1_intro_easy.player.message2.errors': "XThat is not the right answer, but don't worry, this is just practice. Just a quick check. Are you both watching Youtube? Have you found the channel 'CouchClues' and the Playlist 'A Toxic Case'? And are you watching Intro 1? Then open the menu on the top right of your app and open the explanation, then hint 1 and then hint 2.",
        'translate.1_intro_easy.player.message3.title': "XWell done! Now you can pause the video and click the results button below. Your score is determined by the time, hints, wrong answers and if you used the solution. It makes no difference if one or both players use a hint and you can view the hints multiple times.",

        'translate.1_intro_easy.guest.message.title': "XHi {{playerName}}!Welcome to Couch Clues! To play this game, you will need to watch videos together on your TV (or any other screen). We recommend using Vimeo to stream the videos, but you can also watch them on Youtube. <a href=\"https://vimeo.com/showcase/11375804\" target=\"_blank\">Click this link to access Vimeo</a>, enter password 8712 and click 'Start Watching', select ENG subtitles and stream the video to your TV. For Youtube, open the Youtube app on your smart TV, search the 'CCACIPDevelopment' channel and find Playlist 'Couch Second Puzzle GT'. Then play the first video (Intro 1) and select ENG subtitles.",
        'translate.1_intro_easy.guest.message2.title': "XYou will learn the game by playing 2 quick intro challenges with {{partnerName}}. Before you start, make sure that each player has a pen and paper ready. Follow the instructions on your television screen. Your goal is to send me a 3 digit code.",
        'translate.1_intro_easy.guest.message2.errors': "XThat is not the right answer, but don't worry, this is just practice. Just a quick check. Are you both watching Youtube? Have you found the channel 'CouchClues' and the Playlist 'A Toxic Case'? And are you watching Intro 1? Then open the menu on the top right of your app and open the explanation, then hint 1 and then hint 2.",
        'translate.1_intro_easy.guest.message3.title': "XWell done! Now you can pause the video and click the results button below. Your score is determined by the time, hints, wrong answers and if you used the solution. It makes no difference if one or both players use a hint and you can view the hints multiple times.",


        'translate.2_intro_simple.player.option1': "One",
        'translate.2_intro_simple.player.option2': "Two",
        'translate.2_intro_simple.player.option3': "Three",
        'translate.2_intro_simple.player.option4': "Four",
        'translate.2_intro_simple.player.option5': "Five",
        'translate.2_intro_simple.player.option6': "Six",
        'translate.2_intro_simple.player.option7': "Seven",
        'translate.2_intro_simple.player.option8': "Eight",
        'translate.2_intro_simple.player.option9': "Nine",
        'translate.2_intro_simple.player.option10': "Zero",
        'translate.2_intro_simple.guest.option1': "One",
        'translate.2_intro_simple.guest.option2': "Two",
        'translate.2_intro_simple.guest.option3': "Three",
        'translate.2_intro_simple.guest.option4': "Four",
        'translate.2_intro_simple.guest.option5': "Five",
        'translate.2_intro_simple.guest.option6': "Six",
        'translate.2_intro_simple.guest.option7': "Seven",
        'translate.2_intro_simple.guest.option8': "Eight",
        'translate.2_intro_simple.guest.option9': "Nine",
        'translate.2_intro_simple.guest.option10': "Zero",
        'translate.2_intro_simple.timer.title': "Intro 2 - A Case in Paradise",
        'translate.2_intro_simple.timer.subtitle': "You will receive more information in:",
        'translate.2_intro_simple.player.hint.explanation': "Your goal is to find a 4 digit number. You will have to tell each other what you see on your phone and match that with what you see on television. Select the labels in the right order and press submit.",
        'translate.2_intro_simple.player.hint.hint1': "The first digit is 5.",
        'translate.2_intro_simple.player.hint.hint2': "The order on the television is Yellow, Red, Blue, Green. Which numbers match these colours?",
        'translate.2_intro_simple.player.hint.solution': "The solution is Five, Nine, Seven, One. You will have to select the labels in that order. <br> The order of the colours on the television is Yellow, Red, Blue, Green. The yellow number is a five, the red number is a nine, the blue number a seven and the green number a one.",
        'translate.2_intro_simple.guest.hint.explanation': "Your goal is to find a 4 digit number. You will have to tell each other what you see on your phone and match that with what you see on television. Select the labels in the right order and press submit.",
        'translate.2_intro_simple.guest.hint.hint1': "The first digit is 5.",
        'translate.2_intro_simple.guest.hint.hint2': "The order on the television is Yellow, Red, Blue, Green. Which numbers match these colours?",
        'translate.2_intro_simple.guest.hint.solution': "The solution is Five, Nine, Seven, One. You will have to select the labels in that order. <br> The order of the colours on the television is Yellow, Red, Blue, Green. The yellow number is a five, the red number is a nine, the blue number a seven and the green number a one.",
        'translate.2_intro_simple.player.message1.title': "Now it's time for a real practice puzzle. You will receive some images below, try to zoom in on them by tapping on the image and then pinching your fingers.",
        'translate.2_intro_simple.player.message2.title': "Look at the Intro 2 video. Your goal is to find a four digit code.",
        'translate.2_intro_simple.player.gap_fill_text': `Two plus three equals <gap id="1" />. Three multiplied by three equals <gap id="2" />. Lucky number is <gap id="3" />. Zero plus one equals <gap id="4" />.`,
        'translate.2_intro_simple.player.message2.error': "That is not the right code. To try again you have to use the TAG ANSWERS link in the message above to select a different answer.",
        'translate.2_intro_simple.player.message3.title': "That's right! You will now continue to the real game. Have fun together!",
        'translate.2_intro_simple.guest.message1.title': "Now it's time for a real practice puzzle. You will receive some images below, try to zoom in on them by tapping on the image and then pinching your fingers.",
        'translate.2_intro_simple.guest.message2.title': "Look at the Intro 2 video. Your goal is to find a four digit code.",
        'translate.2_intro_simple.guest.message2.error': "That is not the right code. To try again you have to use the TAG ANSWERS link in the message above to select a different answer.",
        'translate.2_intro_simple.guest.message3.title': "That's right! You will now continue to the real game. Have fun together!",

        'translate.2_intro_simple.player.timer.message1': 'During the game you sometimes get an additional message like this one. These messages contain information from the detective that can help you. If you do not want to receive these messages, change your diffuculty to Challenging in the menu on the top right. Everything else will stay the same.',
        'translate.2_intro_simple.guest.timer.message1': 'During the game you sometimes get an additional message like this one. These messages contain information from the detective that can help you. If you do not want to receive these messages, ask the main player to change the difficulty to Challenging in their menu. Everything else will stay the same.',

        'translate.2_intro_easy.player.option1': "One",
        'translate.2_intro_easy.player.option2': "Two",
        'translate.2_intro_easy.player.option3': "Three",
        'translate.2_intro_easy.player.option4': "Four",
        'translate.2_intro_easy.player.option5': "Five",
        'translate.2_intro_easy.player.option6': "Six",
        'translate.2_intro_easy.player.option7': "Seven",
        'translate.2_intro_easy.player.option8': "Eight",
        'translate.2_intro_easy.player.option9': "Nine",
        'translate.2_intro_easy.player.option10': "Zero",
        'translate.2_intro_easy.guest.option1': "One",
        'translate.2_intro_easy.guest.option2': "Two",
        'translate.2_intro_easy.guest.option3': "Three",
        'translate.2_intro_easy.guest.option4': "Four",
        'translate.2_intro_easy.guest.option5': "Five",
        'translate.2_intro_easy.guest.option6': "Six",
        'translate.2_intro_easy.guest.option7': "Seven",
        'translate.2_intro_easy.guest.option8': "Eight",
        'translate.2_intro_easy.guest.option9': "Nine",
        'translate.2_intro_easy.guest.option10': "Zero",
        'translate.2_intro_easy.player.hint.explanation': "Your goal is to find a 4 digit number. You will have to tell each other what you see on your phone and match that with what you see on television. Select the labels in the right order and press submit.",
        'translate.2_intro_easy.player.hint.hint1': "The first digit is 5.",
        'translate.2_intro_easy.player.hint.hint2': "The order on the television is Yellow, Red, Blue, Green. Which numbers match these colours?",
        'translate.2_intro_easy.player.hint.solution': "The solution is Five, Nine, Seven, One. You will have to select the labels in that order. <br> The order of the colours on the television is Yellow, Red, Blue, Green. The yellow number is a five, the red number is a nine, the blue number a seven and the green number a one.",
        'translate.2_intro_easy.guest.hint.explanation': "Your goal is to find a 4 digit number. You will have to tell each other what you see on your phone and match that with what you see on television. Select the labels in the right order and press submit.",
        'translate.2_intro_easy.guest.hint.hint1': "The first digit is 5.",
        'translate.2_intro_easy.guest.hint.hint2': "The order on the television is Yellow, Red, Blue, Green. Which numbers match these colours?",
        'translate.2_intro_easy.guest.hint.solution': "The solution is Five, Nine, Seven, One. You will have to select the labels in that order. <br> The order of the colours on the television is Yellow, Red, Blue, Green. The yellow number is a five, the red number is a nine, the blue number a seven and the green number a one.",
        'translate.2_intro_easy.player.message1.title': "Now it's time for a real practice puzzle. You will receive some images below, try to zoom in on them by tapping on the image and then pinching your fingers.",
        'translate.2_intro_easy.player.message2.title': "Look at the Intro 2 video. Your goal is to find a four digit code.",
        'translate.2_intro_easy.player.message2.error': "That is not the right code. To try again you have to use the TAG ANSWERS link in the message above to select a different answer.",
        'translate.2_intro_easy.player.message3.title': "That's right! You will now continue to the real game. Have fun together!",
        'translate.2_intro_easy.guest.message1.title': "Now it's time for a real practice puzzle. You will receive some images below, try to zoom in on them by tapping on the image and then pinching your fingers.",
        'translate.2_intro_easy.guest.message2.title': "Look at the Intro 2 video. Your goal is to find a four digit code.",
        'translate.2_intro_easy.guest.message2.error': "That is not the right code. To try again you have to use the TAG ANSWERS link in the message above to select a different answer.",
        'translate.2_intro_easy.guest.message3.title': "That's right! You will now continue to the real game. Have fun together!",

        'translate.2_intro_easy.timer.title': "Intro 2 - A Case in Paradise",
        'translate.2_intro_easy.timer.subtitle': "The introduction puzzle will start in:",

        'translate.1_intro_simple.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/Intro1thumb.webp",
        'translate.1_intro_easy.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/Intro1thumb.webp",

        'translate.2_intro_simple.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/Intro2thumb.webp",
        'translate.2_intro_simple.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop15.webp",
        'translate.2_intro_simple.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop17.webp",
        'translate.2_intro_simple.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop21.webp",
        'translate.2_intro_simple.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop29.webp",
        'translate.2_intro_easy.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/Intro2thumb.webp",
        'translate.2_intro_easy.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop15.webp",
        'translate.2_intro_easy.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop17.webp",
        'translate.2_intro_easy.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop21.webp",
        'translate.2_intro_easy.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop29.webp",
    }
);

export default enPuzzleCommon;
