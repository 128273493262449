import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import ProblemStepOne from "../game-problem/ProblemStepOne";
import ProblemStepThree from "../game-problem/ProblemStepThree";
import * as React from "react";
import {useTranslation} from "react-i18next";
import useProblemSteps from "../game-problem/hooks/useProblemSteps";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const WaitingRoomProblemHelp = ({onClose, active}) => {
    const {t} = useTranslation('problemModal')
    const {step, onProceed} = useProblemSteps(active);

    return (
        <Stack gap={2}>
            <Stack direction="row" justifyContent="space-between" position="relative">
                <Typography variant="h6" component="h2">
                    {t('problem')}
                </Typography>
                <IconButton sx={{position: 'absolute', top: -30, right: -30}} onClick={onClose}>
                    <CloseIcon sx={{color: 'white'}}/>
                </IconButton>
            </Stack>
            {step === 1 && <ProblemStepOne onProceed={onProceed} withRestart/>}
            {/*{step === 2 && <ProblemStepTwo onProceed={onProceed}/>}*/}
            {step === 2 && <ProblemStepThree/>}
        </Stack>
    )
}

export default WaitingRoomProblemHelp;