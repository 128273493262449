const nlPuzzleCommon = (
    {
            'translate.game.STANDARD': 'Standaard',
            'translate.game.CHALLENGING': 'Uitdagend',

            'translate.game.hint.solution.label': "Oplossing",
            'translate.game.hint.explanation.label': "Uitleg",
            'translate.game.hint.hints.label': "Hints",
            'translate.game.hint.hint1.label': "Hint 1",
            'translate.game.hint.hint2.label': "Hint 2",
            'translate.game.puzzle.timer.watch_video': "Bekijk de volgende video:",

            'translate.game.hint.explanation_minus_points.label': "Uitleg",
            'translate.game.hint.hint1_minus_points.label': "Hint 1",
            'translate.game.hint.hint2_minus_points.label': "Hint 2",

            'translate.game.paradise': 'A Case in Paradise',
            'translate.game.toxic_case': 'A Toxic Case',

            'translate.game.messages.answers.waiting': "OK, ik weet wat ik moet doen. Laten we ondertussen zien wat de anderen denken.",
            'translate.game.messages.answers.incorrectWhenOpponentCorrect': "Wacht! {{partnerName}} heeft me ook iets gestuurd...",
            'translate.game.messages.answers.allPlayersIncorrect': "Ik heb je oplossingen geprobeerd, maar ze werkten niet.",
            'translate.game.messages.answers.somePlayersIncorrect': "Een of meer antwoorden lijken correct, maar niet allemaal. Bespreek wie hun antwoord moet aanpassen en dien opnieuw in.",
            'translate.game.messages.answers.opponentCorrect': "Jouw oplossing werkte niet, maar die van {{params.correctPlayerNames}} wel!",
            'translate.game.messages.answers.diffAnswersImRightButWaiting': "Wat je zei lijkt correct, maar het antwoord van {{partnerName}} klopt niet. Help {{partnerName}} en laat het me weten als ze klaar zijn.",

            'translate.game.score.65': "Niet slecht! Jullie scoren beter dan 50% van alle spelers",
            'translate.game.score.75': "Netjes! Jullie hebben een betere score dan 75% van alle spelers",
            'translate.game.score.80': "Goede score! Jullie zitten in de top 20%!",
            'translate.game.score.85': "Hele goede score! Jullie horen bij de beste 15% voor deze opdracht",
            'translate.game.score.90': "Fantastische score! Jullie zitten bij de top 10% voor deze opdracht",
            'translate.game.score.95': "Bijna perfect! Jullie zitten bij de top 5% voor deze opdracht!",
            'translate.game.score.100': "Waanzinnig! Maar 1% van de spelers krijgt een score van 100 voor deze puzzel.",
            'translate.game.score.140': "Niet slecht! Jullie scoren beter dan 50% van de spelers op deze moeilijkheidsgraad",
            'translate.game.score.150': "Netjes! Jullie hebben een betere score dan 75% van alle spelers op deze moeilijkheidsgraad",
            'translate.game.score.160': "Mooi resultaat! Jullie zitten bij de top 25% van de spelers die op deze moeilijkheidsgraad spelen",
            'translate.game.score.170': "Goede score! Jullie zitten in de top 20 van de spelers die op Uitdagend spelen",
            'translate.game.score.180': "Hele goede score! Jullie horen bij de beste 15% voor dit hoofdstuk op Uitdagend",
            'translate.game.score.190': "Fantastische score! Jullie zitten bij de top 10% voor dit hoofdstuk op Uitdagend",
            'translate.game.score.195': "Bijna perfect! Jullie zitten bij de top 5% voor deze puzzel! En dat op Uitdagend!",
            'translate.game.score.200': "Waanzinnig! Jullie horen bij de allerbeste ter wereld voor deze opdracht!",
            'translate.game.score.241': "Impressive! Better than 50% of players on 'crazy' difficulty",
            'translate.game.score.250': "Nice! You scored better then 55% of players on 'crazy' difficulty level",
            'translate.game.score.260': "Well done! You are in the top 25% for this challenge (on \"crazy\" level)",
            'translate.game.score.270': "Good score: You two in the top 20% (for \"crazy\" difficulty)",
            'translate.game.score.280': "Very good score! You are in the top 15% for this challenge (on \"crazy \"difficulty",
            'translate.game.score.290': "Great score! You are in the top 10% for this challenge (on \"crazy\" difficulty)",
            'translate.game.score.295': "You are the crazy geniuses within the crazy geniuses group! You are in the top 5%",
            'translate.game.score.300': "Wow! OK, there are 2 options. You are crazy geniuses, or you are cheating. But we don't want to tell crazy geniuses that they are cheating, so well done!",

            'translate.1_intro_simple.player.timer.message1': 'Hey player, maybe you need some help?',
            'translate.1_intro_simple.player.timer.message2': 'It is hard huh? Keep up!',
            'translate.1_intro_simple.player.timer.message3': 'Tick tack tick tack, maybe you need to use a hint?',
            'translate.1_intro_simple.guest.timer.message1': 'Hey guest, maybe you need some help?',
            'translate.1_intro_simple.player.hint.explanation': "Voor iedere opdracht is een uitleg beschikbaar. Deze kun je gebruiken als je vastzit aan het begin van een opdracht en echt geen idee hebt wat je moet doen. Voor deze intro 1 opdracht, moeten jij en je medespeler hetzelfde 3-cijferige getal vinden. Klik hiervoor allebij op Hint 1.",
            'translate.1_intro_simple.player.hint.hint2': "Het eerste cijfer is een 9. Vertel dit aan je medespeler. Elkaar vertellen wat je ziet is cruciaal bij dit spel! Klik nu op Hint 2.",
            'translate.1_intro_simple.player.hint.solution': "De oplossing is 936. <br> Jouw eerste hint was dat het eerste cijfer een 9 is. De eerste hint voor je medespeler was dat het laatste cijfer een 6 was. De tweede hint was hetzelfde voor jullie beide: het tweede cijfer is een 3.",
            'translate.1_intro_simple.player.hint.hint3': "Heeft je medespeler het laatste cijfer verteld? Het tweede cijfer is een 3. Je zou nu de puzzel kunnen oplossen, of de oplossing bekijken om er zeker van te zijn.",
            'translate.1_intro_simple.guest.hint.explanation': "Voor iedere opdracht is een uitleg beschikbaar. Deze kun je gebruiken als je vastzit aan het begin van een opdracht en echt geen idee hebt wat je moet doen. Voor deze intro 1 opdracht, moeten jij en je partner hetzelfde 3-cijferige getal vinden. Klik beide op Hint 1 om deze te vinden.",
            'translate.1_intro_simple.guest.hint.hint2': "Het laatste cijfer is een 6. Vertel dit aan je medespeler. Elkaar vertellen wat je ziet is cruciaal bij dit spel! Klik nu op Hint 2.",
            'translate.1_intro_simple.guest.hint.solution': "De oplossing is 936. <br> Jouw eerste hint was dat het laatste cijfer een 6 is. De eerste hint voor je medespeler was dat het eerste cijfer een 9 was. De tweede hint was hetzelfde voor jullie beide: het tweede cijfer is een 3.",
            'translate.1_intro_simple.guest.hint.hint3': "Heeft je medespeler verteld wat het eerste cijfer is? Het tweede cijfer is een 3. Je zou nu de puzzel kunnen oplossen, of de oplossing bekijken om er zeker van te zijn.",

            'translate.1_intro_simple.player.message.title': "Hi {{playerName}}, welkom bij CouchClues! Dit spel bestaat uit deze mobiele website en videos op je TV (of elk ander scherm). We raden aan om de videos naar je TV te streamen, maar je kunt ze ook kijken op Youtube.  <a href=\"https://vimeo.com/showcase/11216698\" target=\"_blank\">Klik hier om de videos te streamen</a> en vervolgens op 'Start Watching', selecteer de Nederlandse subtitles en stream de video naar je TV. Een andere optie is om Youtube te openen op je tv. Zoek vervolgens naar het kanaal 'CouchClues'. Zoek afspeellijst 'A Toxic Case' en speel de eerste video 'Intro 1' af. Zet de ondertiteling op Youtube op Nederlands.",
            'translate.1_intro_simple.player.message2.title': "Je leert het spel door twee korte introductie opdrachten die je samen met {{partnerName}} moet uitvoeren. Zorg dat je allebei pen en papier bij de hand hebt en volg de instructies op het scherm. Je eerste opdracht is om een 3 cijferige code door te sturen.",
            'translate.1_intro_simple.player.message2.errors': "Dat is niet het juiste antwoord. Maar geen zorgen, dit is puur om te oefenen. Voor de zekerheid een korte check, kijken jullie allebei naar Youtube, het kanaal CouchClues and de Playlist 'A Toxic Case (Nederlands)'? En kijken jullie naar de video 'Introductie - A Toxic Case'? Open dan het menu rechtsboven, eerst de Uitleg, dan Hint 1 en tot slot Hint 2.",
            'translate.1_intro_simple.player.message3.title': "Dat is goed! Pauzeer nu de video en klik op de 'Score' knop hieronder. Jullie score wordt bepaald door de tijd, het aantal gebruikte hints en aantal verkeerde antwoorden. Beide spelers kunnen dezelfde hint bekijken en meerdere keren lezen zonder dat dit extra punten kost.",

            'translate.1_intro_simple.guest.message.title': "Hi {{playerName}}, welkom bij CouchClues! Dit spel bestaat uit deze mobiele website en videos op je TV (of elk ander scherm). We raden aan om de videos naar je TV te streamen, maar je kunt ze ook kijken op Youtube.  <a href=\"https://vimeo.com/showcase/11216698\" target=\"_blank\">Klik hier om de videos te streamen</a> en vervolgens op 'Start Watching', selecteer de Nederlandse subtitles en stream de video naar je TV. Een andere optie is om Youtube te openen op je tv. Zoek vervolgens naar het kanaal 'CouchClues'. Zoek afspeellijst 'A Toxic Case' en speel de eerste video 'Intro 1' af. Zet de ondertiteling op Youtube op Nederlands.",
            'translate.1_intro_simple.guest.message2.title': "Je leert het spel door twee korte introductie opdrachten die je samen met {{partnerName}} moet uitvoeren. Zorg dat je allebei pen en papier bij de hand hebt en volg de instructies op het scherm. Je eerste opdracht is om een 3 cijferige code door te sturen.",
            'translate.1_intro_simple.guest.message2.errors': "Dat is niet het juiste antwoord. Maar geen zorgen, dit is puur om te oefenen. Voor de zekerheid een korte check, kijken jullie allebei naar Youtube, het kanaal CouchClues and de Playlist 'A Toxic Case (Nederlands)'? En kijken jullie naar 'Introductie'? Open dan het menu rechtsboven, eerst de Uitleg, dan Hint 1 en tot slot Hint 2.",
            'translate.1_intro_simple.guest.message3.title': "Dat is goed! Pauzeer nu de video en klik op de 'Score' knop hieronder. Jullie score wordt bepaald door de tijd, het aantal gebruikte hints en aantal verkeerde antwoorden. Beide spelers kunnen dezelfde hint bekijken en meerdere keren lezen zonder dat dit extra punten kost.",

            'translate.1_intro_easy.player.hint.explanation': "Voor alle opdrachten is een uitleg beschikbaar. Deze kun je gebruiken als vastzit aan het begin van een opdracht en je geen idee hebt wat je moet doen. Voor deze intro 1 opdracht, moeten jij en je partner hetzelfde 3-cijferige getal vinden. Klik beide op Hint 1 om deze te vinden.",
            'translate.1_intro_easy.player.hint.hint2': "Het eerste cijfer is een 9. Vertel dit aan je medespeler. Elkaar vertellen wat je ziet is cruciaal bij dit spel! Klik nu op Hint 2.",
            'translate.1_intro_easy.player.hint.solution': "De oplossing is 936. <br> Jouw eerste hint was dat het eerste cijfer een 9 is. De eerste hint voor je medespeler was dat het laatste cijfer een 6 was. De tweede hint was hetzelfde voor jullie beide: het tweede cijfer is een 3.",
            'translate.1_intro_easy.player.hint.hint3': "Heeft je medespeler het laatste cijfer verteld? Het tweede cijfer is een 3. Je zou nu de puzzel kunnen oplossen, of de oplossing bekijken om er zeker van te zijn.",
            'translate.1_intro_easy.guest.hint.explanation': "Voor alle opdrachten is een uitleg beschikbaar. Deze kun je gebruiken als je vastzit aan het begin van een opdracht en je geen idee hebt wat je moet doen. Voor deze intro 1 opdracht, moeten jij en je partner hetzelfde 3-cijferige getal vinden. Klik beide op Hint 1 om deze te vinden.",
            'translate.1_intro_easy.guest.hint.hint2': "Het laatste cijfer is een 6. Vertel dit aan je medespeler. Elkaar vertellen wat je ziet is cruciaal bij dit spel! Klik nu op Hint 2.",
            'translate.1_intro_easy.guest.hint.solution': "De oplossing is 936. <br> Jouw eerste hint was dat het laatste cijfer een 6 is. De eerste hint voor je medespeler was dat het eerste cijfer een 9 was. De tweede hint was hetzelfde voor jullie beide: het tweede cijfer is een 3.",
            'translate.1_intro_easy.guest.hint.hint3': "Heeft je medespeler verteld wat het eerste cijfer is? Het tweede cijfer is een 3. Je zou nu de puzzel kunnen oplossen, of de oplossing bekijken om er zeker van te zijn.",

            'translate.1_intro_easy.player.message.title': "Hi {{playerName}}, welkom bij CouchClues! Dit spel bestaat uit deze mobiele website en videos op je TV (of elk ander scherm). We raden aan om de videos naar je TV te streamen, maar je kunt ze ook kijken op Youtube.  <a href=\"https://vimeo.com/showcase/11216698\" target=\"_blank\">Klik hier om de videos te streamen</a> en vervolgens op 'Start Watching', selecteer de Nederlandse subtitles en stream de video naar je TV. Een andere optie is om Youtube te openen op je tv. Zoek vervolgens naar het kanaal 'CouchClues'. Zoek afspeellijst 'A Toxic Case' en speel de eerste video 'Intro 1' af. Zet de ondertiteling op Youtube op Nederlands.",
            'translate.1_intro_easy.player.message2.title': "Je leert het spel door twee korte introductie opdrachten die je samen met {{partnerName}} moet uitvoeren. Zorg dat je allebei pen en papier bij de hand hebt en volg de instructies op het scherm. Je eerste opdracht is om een 3 cijferige code door te sturen.",
            'translate.1_intro_easy.player.message2.errors': "Dat is niet het juiste antwoord. Maar geen zorgen, dit is puur om te oefenen. Voor de zekerheid een korte check, kijken jullie allebei naar Youtube, het kanaal CouchClues and de Playlist 'A Toxic Case (Nederlands)'? En kijken jullie naar de video 'Introductie' ? Open dan het menu rechtsboven, eerst de Uitleg, dan Hint 1 en tot slot Hint 2.",
            'translate.1_intro_easy.player.message3.title': "Dat is goed! Pauzeer nu de video en klik op de 'Score' knop hieronder. Jullie score wordt bepaald door de tijd, het aantal gebruikte hints en aantal verkeerde antwoorden. Beide spelers kunnen dezelfde hint bekijken en meerdere keren lezen zonder dat dit extra punten kost.",

            'translate.1_intro_easy.guest.message.title': "Hi {{playerName}}, welkom bij CouchClues! Dit spel bestaat uit deze mobiele website en videos op je TV (of elk ander scherm). We raden aan om de videos naar je TV te streamen, maar je kunt ze ook kijken op Youtube.  <a href=\"https://vimeo.com/showcase/11216698\" target=\"_blank\">Klik hier om de videos te streamen</a> en vervolgens op 'Start Watching', selecteer de Nederlandse subtitles en stream de video naar je TV. Een andere optie is om Youtube te openen op je tv. Zoek vervolgens naar het kanaal 'CouchClues'. Zoek afspeellijst 'A Toxic Case' en speel de eerste video 'Intro 1' af. Zet de ondertiteling op Youtube op Nederlands.",
            'translate.1_intro_easy.guest.message2.title': "Je leert het spel door twee korte introductie opdrachten die je samen met {{partnerName}} moet uitvoeren. Zorg dat je allebei pen en papier bij de hand hebt en volg de instructies op het scherm. Je eerste opdracht is om een 3 cijferige code door te sturen.",
            'translate.1_intro_easy.guest.message2.errors': "Dat is niet het juiste antwoord. Maar geen zorgen, dit is puur om te oefenen. Voor de zekerheid een korte check, kijken jullie allebei naar Youtube, het kanaal CouchClues and de Playlist 'A Toxic Case (Nederlands)'? En kijken jullie naar de video 'Introductie'? Open dan het menu rechtsboven, eerst de Uitleg, dan Hint 1 en tot slot Hint 2.",
            'translate.1_intro_easy.guest.message3.title': "Dat is goed! Pauzeer nu de video en klik op de 'Score' knop hieronder. Jullie score wordt bepaald door de tijd, het aantal gebruikte hints en aantal verkeerde antwoorden. Beide spelers kunnen dezelfde hint bekijken en meerdere keren lezen zonder dat dit extra punten kost.",


            'translate.2_intro_simple.player.option1': "Een",
            'translate.2_intro_simple.player.option2': "Twee",
            'translate.2_intro_simple.player.option3': "Drie",
            'translate.2_intro_simple.player.option4': "Vier",
            'translate.2_intro_simple.player.option5': "Vijf",
            'translate.2_intro_simple.player.option6': "Zes",
            'translate.2_intro_simple.player.option7': "Zeven",
            'translate.2_intro_simple.player.option8': "Acht",
            'translate.2_intro_simple.player.option9': "Negen",
            'translate.2_intro_simple.player.option10': "Nul",
            'translate.2_intro_simple.guest.option1': "Een",
            'translate.2_intro_simple.guest.option2': "Twee",
            'translate.2_intro_simple.guest.option3': "Drie",
            'translate.2_intro_simple.guest.option4': "Vier",
            'translate.2_intro_simple.guest.option5': "Vijf",
            'translate.2_intro_simple.guest.option6': "Zes",
            'translate.2_intro_simple.guest.option7': "Zeven",
            'translate.2_intro_simple.guest.option8': "Acht",
            'translate.2_intro_simple.guest.option9': "Negen",
            'translate.2_intro_simple.guest.option10': "Nul",
            'translate.2_intro_simple.timer.title': "Intro 2 - A Toxic Case",
            'translate.2_intro_simple.timer.subtitle': "De oefenopdracht start over:",
            'translate.2_intro_simple.player.hint.explanation': "Je opdracht is om een 4 cijferig getal te vinden. Je zult elkaar moeten vertellen wat je ziet en dat koppelen aan wat je ziet op de televisie. Tag de antwoorden in de juiste volgorde en klik op 'Verstuur'.",
            'translate.2_intro_simple.player.hint.hint1': "Het eerste getal is 5.",
            'translate.2_intro_simple.player.hint.hint2': "De volgorde van de kleuren op de televisie is Geel, Rood, Blauw, Groen. Welke getallen horen bij deze kleuren?",
            'translate.2_intro_simple.player.hint.solution': "De oplossing is Vijf, Negen, Zeven, Een. Je moet de antwoorden in deze volgorde selecteren. <br> Het cijfer 5 is geel, 9 is rood, 7 is blauw en 1 is groen. De volgorde is aangeven door de kleuren op de televisie.",
            'translate.2_intro_simple.guest.hint.explanation': "Je opdracht is om een 4 cijferig getal te vinden. Je zult elkaar moeten vertellen wat je ziet en dat koppelen aan wat je ziet op de televisie. Tag de antwoorden in de juiste volgorde en klik op 'Verstuur'.",
            'translate.2_intro_simple.guest.hint.hint1': "Het eerste getal is 5.",
            'translate.2_intro_simple.guest.hint.hint2': "De volgorde van de kleuren op de televisie is Geel, Rood, Blauw, Groen. Welke getallen horen bij deze kleuren?",
            'translate.2_intro_simple.guest.hint.solution': "De oplossing is Vijf, Negen, Zeven, Een. Je moet de antwoorden in deze volgorde selecteren. <br> Het cijfer 5 is geel, 9 is rood, 7 is blauw en 1 is groen. De volgorde is aangeven door de kleuren op de televisie.",
            'translate.2_intro_simple.player.message1.title': "Nu is het tijd voor een oefenopdracht. Jullie zien informatie in de Intro 2 video en een aantal afbeeldingen in de chat. Oefen met inzoomen door 2x op de afbeelding te tikken en daarna je vingers te spreiden.",
            'translate.2_intro_simple.player.message2.title': "Jullie doel is om een 4 cijferige code te vinden. Klik op TAG ANTWOORDEN om het antwoord door te geven.",
            'translate.2_intro_simple.player.gap_fill_text': "Twee plus drie is <gap />. Drie vermenigvuldigd met drie is <gap />. Geluksgetal is <gap />. Nul plus één is <gap />.",
            'translate.2_intro_simple.player.message2.error': "Dat is niet de juiste oplossing. Om het nog eens te proberen moet je nog een keer op de TAG ANTWOORDEN link klikken uit het bericht hierboven.",
            'translate.2_intro_simple.player.message3.title': "Dat is het juiste getal! Check jullie score, daarna begint het echte spel. Veel plezier met 'A Toxic Case'!",
            'translate.2_intro_simple.guest.message1.title': "Nu is het tijd voor een oefenopdracht. Jullie zien informatie in de Intro 2 video en een aantal afbeeldingen in de chat. Oefen met inzoomen door 2x op de afbeelding te tikken en daarna je vingers te spreiden.",
            'translate.2_intro_simple.guest.message2.title': "Jullie doel is om een 4 cijferige code te vinden. Klik op TAG ANTWOORDEN om het antwoord door te geven.",
            'translate.2_intro_simple.guest.message2.error': "Dat is niet de juiste oplossing. Om het nog eens te proberen moet je nog een keer op de TAG ANTWOORDEN link klikken uit het bericht hierboven.",
            'translate.2_intro_simple.guest.message3.title': "Dat is het juiste getal! Check jullie score, daarna begint het echte spel. Veel plezier met 'A Toxic Case'!",

            'translate.2_intro_simple.player.timer.message1': 'During the game you sometimes get an additional message like this one. These messages contain information from the detective that can help you. If you do not want to receive these messages, change your diffuculty to Challenging in the menu on the top right. Everything else will stay the same.',
            'translate.2_intro_simple.guest.timer.message1': 'During the game you sometimes get an additional message like this one. These messages contain information from the detective that can help you. If you do not want to receive these messages, ask the main player to change the difficulty to Challenging in their menu. Everything else will stay the same.',

            'translate.2_intro_easy.player.option1': "Een",
            'translate.2_intro_easy.player.option2': "Twee",
            'translate.2_intro_easy.player.option3': "Drie",
            'translate.2_intro_easy.player.option4': "Vier",
            'translate.2_intro_easy.player.option5': "Vijf",
            'translate.2_intro_easy.player.option6': "Zes",
            'translate.2_intro_easy.player.option7': "Zeven",
            'translate.2_intro_easy.player.option8': "Acht",
            'translate.2_intro_easy.player.option9': "Negen",
            'translate.2_intro_easy.player.option10': "Nul",
            'translate.2_intro_easy.guest.option1': "Een",
            'translate.2_intro_easy.guest.option2': "Twee",
            'translate.2_intro_easy.guest.option3': "Drie",
            'translate.2_intro_easy.guest.option4': "Vier",
            'translate.2_intro_easy.guest.option5': "Vijf",
            'translate.2_intro_easy.guest.option6': "Zes",
            'translate.2_intro_easy.guest.option7': "Zeven",
            'translate.2_intro_easy.guest.option8': "Acht",
            'translate.2_intro_easy.guest.option9': "Negen",
            'translate.2_intro_easy.guest.option10': "Nul",
            'translate.2_intro_easy.player.hint.explanation': "Je opdracht is om een 4 cijferig getal te vinden. Je zult elkaar moeten vertellen wat je ziet en linken aan wat je ziet op de televisie. Tag de antwoorden in de juiste volgorde en klik op 'Verstuur'.",
            'translate.2_intro_easy.player.hint.hint1': "Het eerste getal is 5.",
            'translate.2_intro_easy.player.hint.hint2': "De volgorde van de kleuren op de televisie is Geel, Rood, Blauw, Groen. Welke getallen horen bij deze kleuren?",
            'translate.2_intro_easy.player.hint.solution': "De oplossing is Vijf, Negen, Zeven, Een. Je moet de antwoorden in deze volgorde selecteren. <br> Het cijfer 5 is geel, 9 is rood, 7 is blauw en 1 is groen. De volgorde is aangeven door de kleuren op de televisie.",
            'translate.2_intro_easy.guest.hint.explanation': "Je opdracht is om een 4 cijferig getal te vinden. Je zult elkaar moeten vertellen wat je ziet en dat linken aan wat je ziet op de televisie. Tag de antwoorden in de juiste volgorde en klik op 'Verstuur'.",
            'translate.2_intro_easy.guest.hint.hint1': "Het eerste getal is 5.",
            'translate.2_intro_easy.guest.hint.hint2': "De volgorde van de kleuren op de televisie is Geel, Rood, Blauw, Groen. Welke getallen horen bij deze kleuren?",
            'translate.2_intro_easy.guest.hint.solution': "De oplossing is Vijf, Negen, Zeven, Een. Je moet de antwoorden in deze volgorde selecteren. <br> Het cijfer 5 is geel, 9 is rood, 7 is blauw en 1 is groen. De volgorde is aangeven door de kleuren op de televisie.",
            'translate.2_intro_easy.player.message1.title': "Nu is het tijd voor een oefenpuzzel. Jullie zien een aantal afbeeldingen in de chat. Oefen met inzoomen door 2x op de afbeelding te tikken en daarna je vingers te spreiden.",
            'translate.2_intro_easy.player.message2.title': "Kijk naar de 'Intro 2' video. Je doel is om een 4 cijferige code te vinden.",
            'translate.2_intro_easy.player.message2.error': "Dat is niet de juiste oplossing. Om het nog eens te proberen moet je nog een keer op de TAG ANTWOORDEN link klikken uit het bericht hierboven.",
            'translate.2_intro_easy.player.message3.title': "Dat is het juiste getal! Check jullie score, daarna begint het echte spel. Veel plezier met 'A Toxic Case'!",
            'translate.2_intro_easy.guest.message1.title': "Nu is het tijd voor een oefenpuzzel. Jullie zien een aantal afbeeldingen in de chat. Oefen met inzoomen door 2x op de afbeelding te tikken en daarna je vingers te spreiden.",
            'translate.2_intro_easy.guest.message2.title': "Kijk naar de 'Intro 2' video. Je doel is om een 4 cijferige code te vinden.",
            'translate.2_intro_easy.guest.message2.error': "Dat is niet de juiste oplossing. Om het nog eens te proberen moet je nog een keer op de TAG ANTWOORDEN link klikken uit het bericht hierboven.",
            'translate.2_intro_easy.guest.message3.title': "Dat is het juiste getal! Check jullie score, daarna begint het echte spel. Veel plezier met 'A Toxic Case'!",

            'translate.2_intro_easy.timer.title': "Intro 2 - A Case in Paradise",
            'translate.2_intro_easy.timer.subtitle': "The introduction puzzle will start in:",

            'translate.1_intro_simple.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/Intro1thumb.webp",
            'translate.1_intro_easy.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/Intro1thumb.webp",

            'translate.2_intro_simple.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/Intro2thumb.webp",
            'translate.2_intro_simple.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop15.webp",
            'translate.2_intro_simple.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop17.webp",
            'translate.2_intro_simple.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop21.webp",
            'translate.2_intro_simple.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop29.webp",
            'translate.2_intro_easy.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/Intro2thumb.webp",
            'translate.2_intro_easy.player.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop15.webp",
            'translate.2_intro_easy.player.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop17.webp",
            'translate.2_intro_easy.guest.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop21.webp",
            'translate.2_intro_easy.guest.media2': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/A+Case+in+Paradise/ACAPintrop29.webp",
    }
);

export default nlPuzzleCommon;
