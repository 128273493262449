import DroppableGap from "./DroppableGap";
import * as React from "react";

export default function TranslationClickableGap(
    {
        id,
        filledGaps,
        transPuzzle,
        onActivate,
    }
){
    const gapId = `gap-${id}`;
    return (
        <DroppableGap
            identifier={gapId}
            onClick={() => onActivate(gapId)}
            content={filledGaps[gapId]
                ? transPuzzle(filledGaps[gapId]?.label)
                : null}
        />
    )
}