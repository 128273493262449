const nlGeneral = ({
    tagOptionsDialog: {
        availableOptions: "Beschikbare opties ({{count}})",
        submitButton: "Verzenden",
        clearBtn: "Leeg",
        selected: "Geselecteerd",
        inOrder: "op volgorde",
    },
    dragOptionsDialog: {
        availableOptions: "Beschikbare opties ({{count}})",
        textChallengeTitle: "Vul de open plekken in",
        textChallengeDescription: "Sleep opties om de ontbrekende delen in te vullen",
        submitButton: "Verzenden",
    },
    fillGapsDialog: {
        availableOptions: "Beschikbare opties ({{count}})",
        textChallengeTitle: "Vul de open plekken in",
        textChallengeDescription: "Klik op de open plekken om een optie te kiezen",
        clearButton: "Wissen",
        submitButton: "Verzenden",
    },
    taggerCarousel: {
        altText: "afbeelding taggen",
        confirmButton: "Bevestigen",
        submitButtonText: "Verzenden",
        tagAllImages: "Tag alle afbeeldingen"
    },
    resultsDialog: {
        resultsTitle: "Resultaten",
        resultsScoreDifficultyCap: "Jullie zijn te slim voor dit niveau! We hebben de moeilijkheidsgraad verhoogd naar uitdagend. Jullie krijgen andere informatie, meer punten en geen hulp meer van Steve... Wil je weer terug naar Standaard? Dan kan speler 1 tijdens de opdracht de moeilijkheid aanpassen in het menu.",
        hintsUsed: "Totaal gebruikte hints",
        incorrectScore: "Totaal incorrecte score",
        solutionsTried: "Totaal geprobeerde oplossingen",
        solutionHintUsed: "oplossingshint gebruikt",
        timeSpent: "Besteedde tijd",
        totalPlayerHintsUsed: "Totaal door speler gebruikte hints",
        totalGuestHintsUsed: "Totaal door gast gebruikte hints",
        totalPlayerSolutionsTried: "Totaal aantal onjuiste pogingen",
        grandTotalResults: "Resultaat alle puzzels",
        totalScore: "Score",
        congratulationsText: "Gefeliciteerd! Jullie hebben de zaak opgelost!",
        waitMessage: "Wacht tot de andere speler op resultaten drukt en probeer opnieuw!",
        goToMenu: "Ga naar Menu",
        continueButton: "Doorgaan",
        curiousFriends: "Wil je de score van jullie vrienden weten?",
        shareWithFriends: "Nodig ze uit en vergelijk jullie scores!",
        shareMessage: "{{playerName}} dagen jullie uit voor een TV escaperoom! Ze hebben een score van {{score}} behaald voor {{puzzleName}} in {{gameName}}. Accepteer je deze uitdaging? Ga naar {{link}}",
        shareGrandTotalMessage: "{{playerName}} dagen jullie uit voor een TV escaperoom! Ze hebben een score van {{score}} behaald in {{gameName}}. Accepteer je deze uitdaging? Ga naar {{link}}",
        solutionExplanation: {
            button: "Bekijk de oplossing",
            modal: {
                title: "Oplossing",
                confirm: "Oké"
            }
        },
    },
    continuePuzzleVideo: {
        // the lab
        'Intro 1': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - Intro 2 - en druk tegelijk op Doorgaan.',
        'Intro 2': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
        'Deceased persons': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 3. Password - en druk tegelijk op Doorgaan.',
        'Differences': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
        'Drone': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 5. Poison - en druk tegelijk op Doorgaan.',
        'Enclose': 'Gefeliciteerd! Jullie hebben het samen opgelost. Klik op Doorgaan om je totaalscore te bekijken en jullie vrienden uit te dagen!',
        'Escape': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
        'Open the door': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 2. Deceased persons - en druk tegelijk op Doorgaan.',
        'Password': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 4. Drone - en druk tegelijk op Doorgaan.',
        'Poison': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 6. Who did it? - en druk tegelijk op Doorgaan.',
        'Tools and wires': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
        'Who did it': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 7. Enclose - en druk tegelijk op Doorgaan.',

        // paradise
        'The Blackout': 'Are you ready for the next challenge? Then start the next video  - 3. The Witnesses - and press Continue!',
        'The Traces': 'Are you ready for the next challenge? Then start the next video - 9. The Security Tapes - and press Continue!',
        'The Key': 'Are you ready for the next challenge? Then start the next video -  5. The Interrogation - and press Continue!',
        'Whodunnit': 'Well done, you solved it together! Press Continue to watch your total score and challenge your friends!',
        'The Missing Items': 'Are you ready for the next challenge? Then start the next video -  8. The Traces - and press Continue!',
        'The Knight': 'Are you ready for the next challenge? Then start the next video  - 2. The Blackout - and press Continue. ',
        'The Witnesses': 'Are you ready for the next challenge? Then start the next video  - 4. The Key - and press Continue!',
        'The Interrogation': 'Are you both ready for the next challenge? Then start the next video  - 6. The Card Game - and press Continue!',
        'The Security Tapes': 'Are you ready for the next challenge? Then start the next video - 10. Whodunnit - and press Continue!',
        'The Card Game': 'Are you ready for the next challenge? Then start the next video  - 7. The Missing Items - and press Continue!',
    },
    puzzleCard: {
        difficultyPlayed: "Gespeelde moeilijkheidsgraad:",
        hintsUsed: "Aantal gebruikte hints:",
        solutionsTried: "Aantal oplossingen geprobeerd:",
        completedIn: "Puzzel voltooid in:",
        expires: "Verloopt",
        seeStats: "Bekijk statistieken"
    },
    problemModal: {
        sorry: "Het spijt ons van de bug! Maar geen zorgen, we zijn hier om het weer op het juiste spoor te brengen!",
        clickContinue: "Door op doorgaan te klikken, wordt deze uitdaging gereset en kun je verdergaan. Houd er rekening mee dat je de spelers opnieuw moet uitnodigen om weer deel te nemen.",
        areYouSure: "Weet je het zeker?",
        reset: "Reset",
        guestNeedRejoin: "De gast moet opnieuw worden uitgenodigd. Deel de link met de gastspeler.",
        inviteContinue: "Uitgenodigd, ga door!",
        doneOrSupport: "Dat is het! Refresh deze pagina en het spel gaat door. Neem contact op met de klantenservice als dit probleem aanhoudt.",
        reloadPage: "Refresh pagina",
        problem: "Probleem?",
    },
    errorModal: {
        difficultyChanged: {
            title: "Moeilijkheidsgraad gewijzigd",
            description: "De host heeft de moeilijkheidsgraad van het spel gewijzigd. Je moet opnieuw deelnemen aan deze puzzel om verder te gaan.",
            button: "Opnieuw deelnemen"
        },
        unknown: {
            title: "Fout",
        }
    },
    navbar: {
        inviteFriend: "Nodig een vriend uit",
        hintMessages: "Hintberichten",
        instructions: "Instructies",
        instructionsUrl: "https://couchclues.com/instructions/",
        problem: "Problemen?",
        quit: "Stoppen",
    },
    difficultyModal: {
        areYouSure: "Weet je zeker dat je de moeilijkheidsgraad wilt veranderen naar",
        dialogContent: "Door de moeilijkheidsgraad te veranderen, start je deze puzzel opnieuw. \n Je gastspeler moet de pagina refreshen om verder te gaan. Weet je zeker dat je wilt doorgaan?",
        cancel: "Annuleren",
        confirm: "Bevestigen",
    },
    gameSeat: {
        hostSubtitle: "Hoofdspeler",
        guestSubtitle: "Gastspeler",
        waitingForPlayer: "Wachten op speler...",
        help: "Hulp?",
        youTag: "JIJ",
        emptyTitle: "Stoel is leeg",
        emptySubtitle: "Gast"
    },
    puzzleWaitingStartModal: {
        titleHostPov: "Wachten op spelers om deel te nemen",
        titleGuestPov: "Wachten tot de puzzel begint",
        titleError: "Fout bij deelname",
        titleInviteMore: "Nodig meer spelers uit",
        puzzleIsFull: "Puzzel is vol",
        startGame: "Start spel",
        waitForStartAlert: "Wacht op de hoofdspeler om het spel te starten",
        startingIn: "Start in {{ secondsRemaining }} seconden",
        kickedOut: "Je bent uit deze puzzel verwijderd.",
        problem: "Probleem?",
    },
    puzzleWaitingAnswersModal: {
        title: "Wachten op andere spelers",
        problem: "Probleem?",
    },
    areYouStillThereModal: {
        title: "Speel je nog?",
        confirm: "Ja"
    },
    ongoingGameInviteModal: {
        invite: {
            title: "Nodig meer spelers uit",
            description: "Om nieuwe gasten uit te nodigen voor het lopende spel, moet je deze puzzel opnieuw starten.",
            areYouSure: "Weet je zeker dat je dit wilt doen?",
            buttonCancel: "Annuleren",
            buttonConfirm: "Ja, opnieuw starten",
        },
        restarted: {
            title: "Puzzel opnieuw gestart",
            description: "Spel opnieuw gestart. Vraag spelers om de pagina te vernieuwen om verder te gaan",
            buttonContinue: "Doorgaan",
        },
    },
    playerProblemHelp: {
        title: "Probleem?",
        copyTextLabel: "Deel deze link zodat de speler opnieuw kan deelnemen",
        or: "of",
        buttonKick: "Speler verwijderen",
    },
    youHaveBeenKickedModal: {
        title: "Oeps!",
        description: "Je maakt geen deel meer uit van dit spel. Dit kan komen doordat het spel opnieuw is gestart of de spelerslijst is aangepast. Als je opnieuw wilt deelnemen, neem dan contact op met de host om een nieuwe uitnodiging te ontvangen.",
        buttonOk: "Ok",
    },
    joinNewMemberModal: {
        title: "{{ name }} heeft je uitgenodigd om te spelen",
        input: {
            name: {
                label: "Voer je naam in"
            },
            email: {
                label: "Voer je e-mail in",
                error: "Voer een geldig e-mailadres in"
            }
        },
        errorFullRoom: "Fout: De puzzel zit al vol",
        "termsConditionsLabel": "Ik ga akkoord met de CouchClues <Link>algemene voorwaarden</Link>",
        submitButtonText: "Deelnemen"
    },
    puzzleCountdown: {
        skipButtonText: "Direct starten",
    }
})

export default nlGeneral;
