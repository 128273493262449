import Typography from "@mui/material/Typography";
import {alpha, Button} from "@mui/material";
import * as React from "react";
import {useTranslation} from "react-i18next";

export default function ProblemStepThree() {
    const {t} = useTranslation('problemModal');

    const onReloadPage = () => {
        window.location.reload();
    }

    return (
        <>
            <Typography id="modal-problem-body-2" variant="body6" sx={{color: alpha('#fff', 0.6)}}>
                {t('doneOrSupport')}
            </Typography>
            <Button size="large" variant="contained" sx={{width: '100%', marginTop: 4}} onClick={onReloadPage}>
                {t('reloadPage')}
            </Button>
        </>
    )
}