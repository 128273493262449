import {createContext, useContext, useMemo, useState} from "react";

export const ProblemContext = createContext({
});

export const useProblemContext = () => useContext(ProblemContext);

const ProblemContextProvider = ({children}) => {
    const [playerProblemHelpActive, setPlayerProblemHelpActive] = useState(null);
    const [waitingRoomProblemActive, setWaitingRoomProblemActive] = useState(false);
    const [gameProblemHelpActive, setGameProblemHelpActive] = useState(false);

    const problemContextValues = useMemo(() => (
            {
                playerProblemHelpActive,
                setPlayerProblemHelpActive,

                waitingRoomProblemActive,
                setWaitingRoomProblemActive,

                gameProblemHelpActive,
                setGameProblemHelpActive,
            }
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [playerProblemHelpActive, waitingRoomProblemActive, gameProblemHelpActive]);

    return (
        <ProblemContext.Provider value={problemContextValues}>
            {children}
        </ProblemContext.Provider>
    )
}

export default ProblemContextProvider;