import React, {useState} from "react";
import {alpha, Stack, Tooltip,} from "@mui/material";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import {WhatsApp} from "@mui/icons-material";
import ContentCopy from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";


const ShareBox = ({ myName, results, puzzleName, gameName, totalScore }) => {
    const { t, i18n } = useTranslation("resultsDialog");
    const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);

    const getMessageText = () => {
        let link = 'https://couchclues.com';
        if (i18n.language !== 'en') {
            link = `${link}/${i18n.language}`
        }
        if (totalScore) {
            return t('shareGrandTotalMessage', { playerName: myName, score: totalScore, gameName: gameName, link});
        }
        return t('shareMessage', { playerName: myName, score: results?.score?.score, puzzleName: puzzleName, gameName: gameName, link});
    }

    const onShareClick = async () => {
        const message = getMessageText();
        if (navigator.share) {
            // Web Share API is supported
            try {
                await navigator.share({ text: message });
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            // Fallback for browsers without Web Share API
            const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
            window.open(whatsappUrl, '_blank');
        }
    };

    const handleCopyClick = () => {
        const textToCopy = getMessageText();
        navigator.clipboard.writeText(textToCopy).then(() => {
            setShowCopiedTooltip(true);
            setTimeout(() => setShowCopiedTooltip(false), 2000);
        }).catch(err => {
            console.error("Failed to copy text: ", err);
        });
    };

    return (
        <Stack sx={{
            backgroundColor: '#182134',
            padding: 2,
            border: 'dashed',
            borderWidth: '4px',
            borderColor: alpha('#fff', 0.5)
        }} direction="column" height="150px" m={1} justifyContent="space-between" alignItems="center" display="flex">
            <Typography variant="h6" component="div" sx={{ color: 'white' }}>
                {t('curiousFriends')}
            </Typography>
            <Typography variant="p" component="div" sx={{color: alpha('#fff', 0.6),}}>
                {t('shareWithFriends')}
            </Typography>
            <Stack direction="row" spacing={1}>
                <WhatsApp sx={{ color: '#25D366', width: '50px', height: '50px' }} onClick={onShareClick}></WhatsApp>
                <Tooltip title="Copied!" open={showCopiedTooltip} placement="top">
                    <IconButton
                        color="inherit"
                        aria-label="menu"
                        sx={{
                            background: '#FF5757',
                            borderColor: '#FF5757',
                            width: '50px',
                            height: '50px'
                        }}
                        onClick={handleCopyClick}
                        // sx={{ color: 'white', margin: '5px' }}
                    >
                        <ContentCopy />
                    </IconButton>
                </Tooltip>
            </Stack>
        </Stack>
    );
};

export default ShareBox;
