import {useEffect, useState} from "react";
import {getMemberIdFromStore, saveMemberIdToStore} from "../util/storageUtil";
import gameApiV2 from "../api/gameApiV2";
import {useNavigate, useParams} from "react-router-dom";
import useIsGuest from "./useIsGuest";

/**
 * Room contains a list of potential players for the puzzles that the Host has purchased
 * In order to play a puzzle, invited users first need to provide their name and join the room to become a "Room Member".
 * When user is already a Member - then they can keep joining puzzles tp become a "Puzzle Player".
 * Host can kick members from the room. Any associated puzzle players loose access to their puzzles as well.
 */
const useJoinRoom = () => {
    const {roomId} = useParams();
    const {isGuest} = useIsGuest(roomId);
    const [loading, setLoading] = useState(false);
    const { memberId: memberIdFromParams, puzzleId } = useParams();
    const [myMemberId, setMyMemberId] = useState(getMemberIdFromStore(roomId));
    const [sendTimerMessages, setSendTimerMessages] = useState(null);
    const navigate = useNavigate();
    const [error, setError] = useState();

    useEffect(() => {
        if (myMemberId) {
            saveMemberIdToStore(roomId, myMemberId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myMemberId]);

    useEffect(() => {
        // if my memberId is only in params but not in session storage (or they mismatch),
        // (e.g. user accidentally closed the browser or and re-opened the link in a new tab)
        // then put memberId from params to state (so that it would be re-saved to storage)
        if (!!memberIdFromParams && Number(memberIdFromParams) !== myMemberId) {
            setMyMemberId(Number(memberIdFromParams))
        }

        // when guest memberId is known (i.e. they have joined the room)
        // put that memberId to url
        if (!memberIdFromParams && !!myMemberId) {
            if (roomId && puzzleId && isGuest) {
                navigate(`/${roomId}/${puzzleId}/guest/${myMemberId}`)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberIdFromParams, myMemberId]);

    const joinRoomAsGuest = ({ name, email }) => {
        setLoading(true);
        gameApiV2.joinRoomAsNewGuestMember(roomId, name, email)
            .then(it => {
                setMyMemberId(it.memberId);
                setSendTimerMessages(it.sendTimerMessages);
            })
            .catch(setError)
            .finally(() => setLoading(false));
    }

    const joinRoomAsHost = () => {
        setLoading(true);
        gameApiV2.joinRoomAsHost(roomId)
            .then(it => {
                setMyMemberId(it.memberId);
                setSendTimerMessages(it.sendTimerMessages);
            })
            .catch(console.error)
            .finally(() => setLoading(false));
    }

    return ({
        myMemberId,
        sendTimerMessages,
        joinRoomAsGuest,
        joinRoomAsHost,
        loading,
        error,
    })
}

export default useJoinRoom;