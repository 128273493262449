import React from 'react';
import {alpha, Box, Button, Typography} from '@mui/material';
import { useLocation } from 'react-router-dom'
import {getLatestRoomId, isGuestFromStore} from "../util/storageUtil";
import {useTranslation} from "react-i18next";

export default function ErrorPage() {
    const { state } = useLocation();
    const { t } = useTranslation();
    const navigateOutApp = () => {
        if (getLatestRoomId()) {
            window.location.href = getLatestRoomId();
            return;
        }
        window.open("https://couchclues.com/", "_blank", "noreferrer");
    }

    const getTitle = () => {
        if (state?.title) {
            return state.title;
        }
        return t('notExists');
    }

    const getUserTypeError = () => {

        const isGuest = isGuestFromStore();
        if (isGuest === null) {
            return "Unknown";
        }
        if (isGuest) {
            return "Guest"
        }
        if (!isGuest) {
            return "Player";
        }
        return "unknown";
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                flexDirection: 'column'
            }}
            spacing={2}
        >
            <Typography variant="h3" sx={{ color: 'white' }}>
                {`${getUserTypeError()} `}{t('error')}
            </Typography>
            <Typography variant="h6" sx={{ color: alpha('#fff', 0.6) }} m={1}>
                {getTitle()}
            </Typography>
            <Button variant="contained" onClick={navigateOutApp}>{t('errorGoHome')}</Button>
        </Box>
    );
}
