import React from 'react';
import {alpha, Button, Drawer, Stack} from '@mui/material';
import Typography from "@mui/material/Typography";
import CloseIcon from '@mui/icons-material/Close';

export default function OptionsDrawer({open, onClose, options, title, onSelect, activeOption, onClear}) {
    const renderOption = (option) => {
        const isActive = activeOption && option.id === activeOption.id;
        return (
            <Stack position="relative" key={option.id}>
                <Button
                    sx={{
                        touchAction: 'none',
                        color: 'black',
                        paddingX: 2,
                        borderRadius: '6px',
                        fontSize: '15px',
                        height: '36px',
                        backgroundColor: '#DCF8C7',
                        textTransform: 'none',
                        border: isActive ? '1px solid black' : '1px solid transparent',
                        '&:hover': {
                            border: '1px solid black',
                        }
                    }}
                    onClick={() => onSelect(option)}
                >
                    {option.label}
                </Button>
                {isActive && (
                        <Stack
                            borderRadius="50%"
                            p={0.5}
                            bgcolor="#FF5757"
                            position="absolute"
                            onClick={onClear}
                            sx={{
                                top: -8,
                                right: -8,
                                cursor: 'pointer',
                        }}
                        >
                            <CloseIcon sx={{color: 'white', fontSize: 15 }}/>
                        </Stack>
                    )}
            </Stack>
        );
    }

    return (
        <Drawer
            anchor='right'
            open={!!open}
            sx={{
                '& .MuiBackdrop-root': {
                    backgroundColor: alpha('#000', 0.1),
                    marginTop: '56px',
                },
                zIndex: 3000,
                '& .MuiPaper-root': {
                    borderTop: '1px solid #D2D2D2',
                    boxShadow: 'none',
                    backgroundColor: '#f6f6f6 !important',
                },
            }}
            onClose={onClose}
            PaperProps={{sx: {width: "40%", minWidth: '240px', marginTop: '56px'}}}
        >
            <Stack gap={1.5} direction="row" flexWrap="wrap" p={2}>
                <Typography component="div" color="text.secondary" fontSize={16}>
                    {title}
                </Typography>

                {options?.map(renderOption)}
            </Stack>
        </Drawer>
    );
};