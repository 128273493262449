import SendIcon from "@mui/icons-material/Send";
import * as React from "react";
import {styled} from "@mui/material";
import Button from "@mui/material/Button";

const ButtonSx = styled(Button)`
  background-color: #88CD96 !important;
  box-shadow: none;
  height: 46px;

  &:disabled {
    background-color: lightgray !important;
    opacity: 0.6;
  }
`;

const AnswerSubmitButton = ({ children, onClick, disabled }) => {
    return (
        <ButtonSx
            fullWidth
            disabled={disabled}
            endIcon={<SendIcon/>}
            variant="contained"
            onClick={onClick}
        >
            {children}
        </ButtonSx>
    )
}

export default AnswerSubmitButton;