import {Button, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useEffect} from "react";
import {CopyField} from "@eisberg-labs/mui-copy-field";
import {getMemberInviteLink} from "../../utils/shared";
import {useRoomContext} from "../../context/RoomContextProvider";
import {usePuzzleContext} from "../../context/PuzzleContextProvider";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from "react-i18next";

const PlayerProblemHelp = ({onClose, player}) => {
    const {t} = useTranslation('playerProblemHelp');
    const {roomId, isHost, kickMember, kickMemberLoading, kickMemberSuccess} = useRoomContext();
    const {puzzleId} = usePuzzleContext();

    useEffect(() => {
        if (kickMemberSuccess) {
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kickMemberSuccess]);

    const onKickMember = isHost
        ? () => kickMember({roomId, memberId: player.memberId, puzzleId})
        : undefined;

    return (
        <>
            <Stack direction="row" justifyContent="space-between" position="relative">
                <Typography variant="h6" component="h2">
                    {t('title')}
                </Typography>
                <IconButton sx={{position: 'absolute', top: -30, right: -30}} onClick={onClose}>
                    <CloseIcon sx={{color: 'white'}}/>
                </IconButton>
            </Stack>

            <Stack
                mb={1}
                sx={{borderTop: '1px solid', borderColor: '#313b50'}}
                pt={2}
            >
                <Stack spacing={2}>
                    <Typography fontSize={16} sx={{paddingLeft: 1}}>
                        {t('copyTextLabel')}
                    </Typography>
                    <CopyField
                        sx={{marginTop: 1}}
                        value={getMemberInviteLink(roomId, puzzleId, player?.memberId)}
                    />

                    <Stack direction="row" alignItems="center" gap={3}>
                        <Stack sx={{borderTop: '1px solid', borderColor: '#313b50'}} width="100%"/>
                        {t('or')}
                        <Stack sx={{borderTop: '1px solid', borderColor: '#313b50'}} width="100%"/>
                    </Stack>
                    <Button variant="contained" onClick={onKickMember} disabled={kickMemberLoading}>
                        {t('buttonKick')}
                    </Button>
                </Stack>
            </Stack>
        </>
    )
}

export default PlayerProblemHelp;