import {Avatar, Message} from "@chatscope/chat-ui-kit-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {getMsgDirection, getTitle, resolveMsgType} from "../ChatHelper";
import {getDetectiveAvatar} from "../../utils/gameUtils";

const SimpleMessage = ({m, playerNames, gameData}) => {
    const { t: transPuzzle } = useTranslation('puzzle');

    return (
        <Message  model={{
            type: resolveMsgType(m),
            message: getTitle(m.title, transPuzzle, playerNames, m.params),
            sender: m.sender,
            direction: getMsgDirection(m.sender)
        }}>
            {m?.sender === 'game' && <Avatar src={getDetectiveAvatar(gameData)} name={m.sender} /> }
        </Message>
    )
}

export default SimpleMessage
